import { TextInput } from "@mantine/core";
import { IconSearch } from "@tabler/icons";
import React from "react";

const SearchBox = ({ placeholder = "Search", ...props }) => {
  return (
    <TextInput icon={<IconSearch />} placeholder={placeholder} {...props} />
  );
};

export default SearchBox;
