import React from "react";
import { useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";
//import { isLogin } from "../services";

import { userSelector } from "../reducers/userInfoSlice";

export default function PrivateRoute({ component: Component, ...rest }) {
  const { isLogin } = useSelector(userSelector);
  //console.log('is login: ' + isLogin);

  return (
    <Route
      {...rest}
      render={(props) =>
        isLogin ? <Component {...props} /> : <Redirect to="/page" />
      }
    />
  );
}
