import React from "react";
import { Affix, Button, Text, Transition, Box } from "@mantine/core";
import { IconArrowUp } from "@tabler/icons";
import { useWindowScroll } from "@mantine/hooks";

export default function Footer() {
  const [scroll, scrollTo] = useWindowScroll();

  return (
    <Affix position={{ bottom: 20, right: 20 }}>
      <Box>
        <strong>
          Copyright © 2014-2023{" "}
          <a href="http://www.verismart.co.th/">Verismart</a>.
        </strong>
      </Box>
    </Affix>
  );
}
