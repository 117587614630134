import axios from "axios";
import { getToken } from "../../@core/services";
import { AppConfig } from "../../AppConfig";

class CourseService {
  constructor() {}
  async getCourseTypes() {
    const page = 1;
    const size = 200;
    const urlAPI = `${AppConfig.apiURL}/api/courseTypes?page=${page}&size=${size}`;

    //const urlAPI = `${AppConfig.apiURL}/api/courses?page=1&size=200`;
    const config = {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    };
    const response = await axios.post(urlAPI, { searchBox: "" }, config);
    //console.log(response.data.data.rows);
    if (response.status === 200) {
      return response.data.data.rows;
    }
  }
  async getCourses(expired = 0) {
    const page = 1;
    const size = 200;
    const urlAPI = `${AppConfig.apiURL}/api/courses?page=${page}&size=${size}`;

    //const urlAPI = `${AppConfig.apiURL}/api/courses?page=1&size=200`;
    const config = {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    };
    const response = await axios.post(
      urlAPI,
      { searchBox: "", expired: expired },
      config
    );
    if (response.status === 200) {
      return response.data;
    }
  }
  async getCourseSet(id) {
    const urlAPI = `${AppConfig.apiURL}/api/courses/courseSet`;
    const config = {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    };
    var data = { courseId: id };
    const response = await axios.post(urlAPI, data, config);
    // [{ID, NAME, INSTRUCTORID,DAYOFWEEK,STARTTIME,ENDTIME.COURSETYPEID}]
    if (response.status === 200) {
      return response.data;
    } else return response;
  }
  async insert(data) {
    const urlAPI = `${AppConfig.apiURL}/api/courses/insert`;
    const config = {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    };
    const response = await axios.post(urlAPI, data, config);
    //console.log(response);
    if (response.status === 200) {
      return { status: "00" };
    }
  }
  async update(data) {
    const urlAPI = `${AppConfig.apiURL}/api/courses/update`;
    const config = {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    };
    const response = await axios.post(urlAPI, data, config);
    //console.log(response);
    if (response.status === 200) {
      return { status: "00" };
    }
  }
  async get(id) {
    const urlAPI = `${AppConfig.apiURL}/api/courses/${id}`;
    const config = {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    };
    const response = await axios.get(urlAPI, config);
    //console.log(response);
    if (response.status === 200) {
      return response.data;
    } else return null;
  }
  async delete(data) {
    const urlAPI = `${AppConfig.apiURL}/api/courses/delete`;
    const config = {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    };
    let response = null;
    try {
      response = await axios.post(urlAPI, data, config);
      if (response.status === 200) {
        return { status: "00" };
      }
    } catch (error) {
      throw error;
    }
    //console.log(response);
  }
}

export default CourseService;
