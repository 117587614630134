import axios from "axios";
import { getToken } from "../../@core/services";
import { AppConfig } from "../../AppConfig";

class ReportService {
  constructor() {}

  async downloadCoursesReport() {
    const urlAPI = `${AppConfig.apiURL}/api/courses/report`;
    const config = {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    };
    const response = await axios.get(urlAPI, config);
    //console.log(response);
    if (response.status === 200) {
        //console.log(response);
        return response.data;
    }
  }  
}

export default ReportService;
