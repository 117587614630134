import React from "react";

import Content from "../@themes/adminlte/components/content/Content";
import Footer from "../@themes/adminlte/components/footer/Footer";
import Menu from "../@themes/adminlte/components/menu/Menu";
import { AppShell } from "@mantine/core";

export default function Backoffice() {
  document.getElementById("adminBody").className =
    "hold-transition sidebar-mini";
  return (
    <div>
      <AppShell
        navbar={<Menu />}
        footer={<Footer />}
        style={{ backgroundColor: "#DEE2E6" }}
        navbarOffsetBreakpoint="sm"
      >
        <Content />
      </AppShell>
    </div>
  );
}
