import {
  Avatar,
  Card,
  Center,
  Group,
  Highlight,
  LoadingOverlay,
  SimpleGrid,
  Text,
  Tooltip,
} from "@mantine/core";
import { IconCircleCheck, IconClockHour4 } from "@tabler/icons";
import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import ClassCard from "../../../../@core/components/ClassCard";
import { getToken } from "../../../../@core/services";
import {
  customFormatDateTime,
  formatUTCTime,
} from "../../../../@core/utilities";
import { AppConfig } from "../../../../AppConfig";

const TodaySchedule = ({ date }) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const weekday = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const filterByDOW = useCallback((list = []) => {
    return list.filter((item) => {
      if ("dayOfWeek" in item) {
        const endCourse = new Date(item.endCourse);
        return (
          item.dayOfWeek === weekday[date.getDay()] &&
          item.courseTypeName === "Open" &&
          endCourse > date
          //&& item.date < date
        );
      } else if ("date" in item) {
        var itemDate = new Date(item.date);
        return weekday[itemDate.getDay()] === weekday[date.getDay()];
      }
    });
  });

  const filterByToday = useCallback(async (list = []) => {
    const config = {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    };

    const urlAPI = `${AppConfig.apiURL}/api/todayClasses?page=${1}&size=${100}`;
    const response = await axios.post(urlAPI, { searchBox: "" }, config);
    var todays = filterByDOW(response.data.data.rows);
    var newData = [];
    console.log(todays);
    list.forEach((item, index) => {
      const res = todays.find(
        (t) =>
          t.courseName === item.courseName &&
          customFormatDateTime(date, `YYYY-MM-DD`) ===
            customFormatDateTime(t.date, `YYYY-MM-DD`) &&
          item.active &&
          t.active
      );
      if (res) newData.push({ ...item, valid: true });
      else newData.push({ ...item, valid: false });
    });
    return newData;
  });

  const fetchCurrentSchedule = useCallback(async () => {
    const config = {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    };

    const urlAPI = `${AppConfig.apiURL}/api/courses?page=${1}&size=${100}`;
    const response = await axios.post(
      urlAPI,
      { searchBox: "", expired: 0 },
      config
    );
    const filteredByDOW = filterByDOW(response.data.data.rows);
    const filteredByToday = await filterByToday(filteredByDOW);
    console.log("filtered by today: ", filteredByToday);
    setData(filteredByToday);

    setLoading(false);
  }, [date]);

  useEffect(() => {
    setLoading(true);

    fetchCurrentSchedule();

    return () => {};
  }, [date]);

  return (
    <div>
      <LoadingOverlay visible={loading} />
      <Center mb={5}>
        <Text fw="bold" mt={5} mb={5}>
          <Highlight highlight={[`${weekday[date.getDay()]}`]}>
            {`Schedule for ${customFormatDateTime(date, `YYYY-MM-DD`)} (
            ${date && weekday[date.getDay()]})`}
          </Highlight>
        </Text>
      </Center>

      <SimpleGrid cols={4}>
        {data != null &&
          data.map((item, index) => {
            return <ClassCard item={item} />;
          })}
      </SimpleGrid>
    </div>
  );
};

export default TodaySchedule;
