import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import Swal from "sweetalert2/dist/sweetalert2";

import {
  loginUser,
  userSelector,
  clearState,
} from "../@core/reducers/userInfoSlice";
import { AppConfig } from "../AppConfig";

export default function Login(props) {
  document.getElementById("adminBody").className = "hold-transition login-page";

  const dispatch = useDispatch();
  const { isSuccess, isError, errorMessage } = useSelector(userSelector);
  const { register, handleSubmit } = useForm();
  const onSubmit = (data) => {
    if (data.username === "" || data.passwordvalue === "") {
      Swal.fire({
        title: "Error!",
        text: "Please put username or password",
        icon: "error",
        confirmButtonText: "Ok",
      });
      return;
    }
    dispatch(loginUser(data));
  };

  useEffect(() => {
    return () => {};
  }, []);

  useEffect(() => {
    return () => {
      dispatch(clearState());
    };
  });

  useEffect(() => {
    if (isError) {
      Swal.fire({
        title: "Error!",
        text: errorMessage,
        icon: "error",
        confirmButtonText: "OK",
      });
      dispatch(clearState());
    }

    if (isSuccess) {
      dispatch(clearState());
      props.history.push(`${AppConfig.applicationPath}/coursetaking`);
    }
  }, [isError, isSuccess]);

  return (
    <>
      <div className="login-box">
        <div className="login-logo" style={{ height: 220 }}>
          <a href="#empty">
            {/* <b>HR</b> */}
            <img
              src={process.env.PUBLIC_URL + "/banner.png"}
              alt="HS logo"
              className="img-fluid"
            />
          </a>
        </div>
        {/* /.login-logo */}
        <div className="card">
          <div className="card-body login-card-body">
            <p className="login-box-msg">Sign in to start your session</p>
            <form method="post" onSubmit={handleSubmit(onSubmit)}>
              <div className="input-group mb-3">
                <input
                  autoFocus
                  type="text"
                  name="username"
                  className="form-control"
                  placeholder="Email"
                  ref={register()}
                />
                <div className="input-group-append">
                  <div className="input-group-text">
                    <span className="fas fa-envelope" />
                  </div>
                </div>
              </div>
              <div className="input-group mb-3">
                <input
                  type="password"
                  name="passwordvalue"
                  className="form-control"
                  placeholder="Password"
                  ref={register()}
                />
                <div className="input-group-append">
                  <div className="input-group-text">
                    <span className="fas fa-lock" />
                  </div>
                </div>
              </div>
              <div className="row">
                {/* /.col */}
                <div className="col-4">
                  <button type="submit" className="btn btn-primary btn-block">
                    Log In
                  </button>
                </div>
                {/* /.col */}
              </div>
            </form>
          </div>
          {/* /.login-card-body */}
        </div>
      </div>
      <div className="fixed-bottom">
        <div className="row">
          <div className="col-10"></div>
          <div className="col-2 d-flex flex-row-reverse">
            <span style={{ fontSize: 12, color: "#B0B0B0", paddingRight: 10 }}>
              3.2.9
            </span>
          </div>
        </div>
      </div>
    </>
  );
}
