import React, {
  useMemo,
  useRef,
  useState,
  useEffect,
  useCallback,
} from "react";
import AdvancedPaginationTable from "../../../@core/components/AdvancedPaginationTable";
import Swal from "sweetalert2";
import { format } from "date-fns";
import DatePicker from "react-datepicker";

import { numberFormat, toCSVHeader } from "../../../@core/utilities/index";
import { AppConfig } from "../../../AppConfig";
import PaymentService from "../../../services/process/PaymentService";
import axios from "axios";
import { getToken } from "../../../@core/services";
import { Button, Checkbox, Divider, Group } from "@mantine/core";
import { IconArrowsExchange2, IconCirclePlus, IconTrash } from "@tabler/icons";
import SearchBox from "../../../@core/components/SearchBox";
import DateInput from "../../../@core/components/DateInput";
const initialDate = { startDate: null, endDate: null };

export default function Payment(props) {
  const [dateFilter, setDateFilter] = useState(initialDate);
  const [searchBox, setSearchBox] = useState("");
  const [isReceived, setIsReceived] = useState(true);
  const [isClosedPayment, setIsClosed] = useState(false);
  const resetFilter = () => {
    setDateFilter(initialDate);
    setSearchBox("");
    setIsReceived(true);
    setIsClosed(false);
  };

  const childRef = useRef();
  const fetchCourseType = async () => {
    const config = {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    };
    const urlAPI = `${AppConfig.apiURL}/api/courseTypes?page=${1}&size=${100}`;
    const response = await axios.post(urlAPI, null, config);
    setCourseTypeOptions(response.data.data.rows);
  };
  useEffect(() => {
    var isCancelled = false;
    if (!isCancelled) {
      fetchCourseType();
    }
    resetFilter();
    childRef.current.setHeader(toCSVHeader(csvCols));
    return () => {
      isCancelled = true;
      // resetFilter();
    };
  }, []);
  useEffect(() => {
    var isCancelled;
    if (!isCancelled) {
      childRef.current.setFilter({
        startDate: dateFilter.startDate,
        endDate: dateFilter.endDate,
        searchBox,
        isClosedPayment,
        isReceived,
      });
    }
    return () => {
      isCancelled = true;
    };
  }, [searchBox, dateFilter, isClosedPayment, isReceived]);
  const formatDateFilter = (date = null) => {
    var result = { startDate: null, endDate: null };
    if (date.startDate != null)
      result.startDate = format(new Date(date.startDate), "yyyy-MM-dd");
    if (date.endDate != null)
      result.endDate = format(new Date(date.endDate), "yyyy-MM-dd");

    return result;
  };

  const [courseTypeOptions, setCourseTypeOptions] = useState(null);
  const getCourseTypeName = useCallback((id) => {
    if (courseTypeOptions === null) return id;
    else return courseTypeOptions.filter((c) => c.id === id)[0].name;
  });
  const csvCols = useMemo(() => [
    {
      name: "Student Code",
      selector: "studentCode",
      sortable: false,
    },
    {
      name: "First name",
      selector: "firstName",
      sortable: false,
    },
    {
      name: "Last name",
      selector: "lastName",
      sortable: false,
    },
    {
      name: "Course Type",
      selector: "couseTypeName",
    },

    {
      name: "Course",
      selector: "fixedCourseName",
    },

    {
      name: "Qty",
      selector: "qty",
    },
    {
      name: "Price",
      selector: "price",
    },
    {
      name: "Discount",
      selector: "discount",
    },
    {
      name: "Total",
      selector: "tota;",
    },
    {
      name: "Date",
      selector: "paymentDateTime",
    },
    {
      name: "Expire",
      selector: "expDate",
    },
    {
      name: "Payment Channel",
      selector: "paymentName",
    },
    {
      name: "Receive",
      selector: "isReceived",
    },
    {
      name: "Early Closed",
      selector: "isClosedPayment",
    },
    {
      name: "Remark",
      selector: "remark",
    },
  ]);
  const columns = useMemo(() => [
    {
      name: "Student Code",
      selector: "studentCode",
      sortable: false,
    },
    {
      name: "First name",
      selector: "firstName",
      sortable: false,
    },
    {
      name: "Last name",
      selector: "lastName",
      sortable: false,
    },
    {
      name: "Course Type",

      sortable: false,
      cell: (row) => <div>{getCourseTypeName(row.courseTypeId)}</div>,
    },

    {
      name: "Course",
      sortable: false,
      grow: 2,
      cell: (row) => (
        <div>
          {row.isFixed && (
            <span>{`${row.fixedCourseName} (${row.instructorName})`}</span>
          )}
        </div>
      ),
    },

    {
      name: "Qty",
      sortable: false,
      right: true,
      cell: (row) => (
        <div>{row.qty != null ? numberFormat(row.qty) : row.qty}</div>
      ),
    },
    {
      name: "Price",
      sortable: false,
      right: true,
      cell: (row) => (
        <div>{row.price != null ? numberFormat(row.price) : row.price}</div>
      ),
    },
    {
      name: "Discount",
      sortable: false,
      right: true,
      cell: (row) => (
        <div>
          {row.discount != null ? numberFormat(row.discount) : row.discount}
        </div>
      ),
    },
    {
      name: "Total",
      sortable: false,
      right: true,
      cell: (row) => (
        <div>{row.total != null ? numberFormat(row.total) : row.total}</div>
      ),
    },
    {
      name: "Date",
      sortable: false,
      cell: (row) => (
        <div>{format(new Date(row.paymentDateTime), "dd/MM/yyyy")}</div>
      ),
    },
    {
      name: "Expire",
      sortable: false,
      cell: (row) =>
        row.expired === null ? (
          <></>
        ) : (
          <div>{format(new Date(row.expired), "dd/MM/yyyy")}</div>
        ),
    },
    {
      name: "Payment Channel",
      sortable: false,
      cell: (row) => <div>{row.paymentName}</div>,
    },
    {
      name: "Receive",
      sortable: false,
      // right: true,
      center: true,
      cell: (row) => {
        return row.isReceived ? (
          <i className="fas fa-check"></i>
        ) : (
          <i className="fas fa-times"></i>
        );
      },
    },
    {
      name: "Early Closed",
      sortable: false,
      // right: true,
      center: true,
      cell: (row) => {
        return row.isClosedPayment ? (
          <i className="fas fa-check"></i>
        ) : (
          <i className="fas fa-times"></i>
        );
      },
    },
    {
      name: "Remark",
      sortable: false,
      right: false,
      cell: (row) => <div>{row.remark}</div>,
    },
    {
      cell: (row) => (
        <div>
          <a
            href="#empty"
            onClick={(e) => {
              e.preventDefault();
              editOnclick(row);
            }}
          >
            <i className="fas fa-pencil-alt mr-3"></i>
          </a>
          <a
            href="#empty"
            onClick={(e) => {
              e.preventDefault();
              deleteOnClick(row);
            }}
          >
            <i className="fas fa-trash-alt"></i>
          </a>
        </div>
      ),
    },
  ]);
  const newOnclick = (row) => {
    props.history.push(`${AppConfig.applicationPath}/payment/new`);
  };
  const editOnclick = (row) => {
    props.history.push(`${AppConfig.applicationPath}/payment/${row.id}`);
  };
  const deleteData = async (id) => {
    const dataSubmit = {
      paymentId: id,
    };
    const handler = new PaymentService();
    try {
      await handler.delete(dataSubmit);
      Swal.fire("Deleted!", "Your file has been deleted.", "success");
      childRef.current.setFilter({
        dateFilter: formatDateFilter(dateFilter),
        searchBox,
        isClosedPayment,
        isReceived,
      });
    } catch (error) {
      console.log("error");
      Swal.fire("Deleted!", "Delete fail.", "error");
    }
  };
  const deleteOnClick = async (row) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Are you sure you want to delete this information?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Please Wait !",
          html: "System processing", // add html attribute if you want or remove
          allowOutsideClick: false,
          showCancelButton: false,
          showConfirmButton: false,
          willOpen: () => {
            Swal.showLoading();
            try {
              deleteData(row.id);
            } catch (error) {
              console.log("error");
            }

            Swal.close();
          },
        });
      }
    });
  };

  return (
    <section className="content">
      <div className="container-fluid">
        <div className="row mt-2 mb-2">
          <div className="col-12">
            <div className="card">
              <div className="card-header">
                <h3 className="card-title">Payment</h3>
                <div className="card-tools"></div>
              </div>
              {/* /.card-header */}
              <div className="card-body">
                <Group mb={10}>
                  <DateInput
                    name="startDate"
                    value={dateFilter.startDate}
                    // dateFormat="dd/MM/yyyy"
                    placeholder="Start Date"
                    onChange={(date) =>
                      setDateFilter({
                        ...dateFilter,
                        startDate: date === "" ? null : date,
                      })
                    }
                  />
                  <IconArrowsExchange2 color="gray" />
                  <DateInput
                    name="endDate"
                    value={dateFilter.endDate}
                    // dateFormat="dd/MM/yyyy"
                    placeholder="End Date"
                    onChange={(date) =>
                      setDateFilter({
                        ...dateFilter,
                        endDate: date === "" ? null : date,
                      })
                    }
                  />
                  <Divider orientation="vertical" size="md" />
                  <SearchBox
                    name="search"
                    value={searchBox}
                    onChange={(e) => {
                      e.preventDefault();
                      setSearchBox(e.target.value);
                    }}
                  />
                  <Checkbox
                    // size="xs"
                    checked={isReceived}
                    onChange={(e) => setIsReceived(e.target.checked)}
                    label="Received"
                  />
                  <Checkbox
                    // size="xs"
                    checked={isClosedPayment}
                    onChange={(e) => setIsClosed(e.target.checked)}
                    label="Early Closed"
                  />
                </Group>

                <Button
                  leftIcon={<IconCirclePlus />}
                  onClick={newOnclick}
                  color="blue"
                >
                  New
                </Button>
                <Button
                  leftIcon={<IconTrash />}
                  onClick={resetFilter}
                  style={{ marginLeft: 5 }}
                >
                  Clear Filter
                </Button>

                <AdvancedPaginationTable
                  ref={childRef}
                  title="Payment"
                  columns={columns}
                  apiURL="\api\payments"
                  isSelectableRows={false}
                  noHeader={true}
                />
              </div>
              {/* /.card-body */}
            </div>
          </div>
          {/* /.col */}
        </div>
        {/* /.row */}
      </div>
      {/* /.container-fluid */}
    </section>
  );
}
