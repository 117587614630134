import React, { useMemo, useRef, useEffect } from "react";
import AdvancedPaginationTable from "../../../@core/components/AdvancedPaginationTable";
import { datetimeToString } from "../../../@core/utilities";
export default function MessageList(props) {
  const childRef = useRef();

  const searchOnClick = () => childRef.current.doSearch();

  const columns = useMemo(() => [
    {
      name: "Message",
      selector: "message",
      sortable: false,
    },
    {
      name: "Start Time",
      sortable: false,
      cell: (row) => <div>{datetimeToString(row.startTime)}</div>,
    },
    {
      name: "End Time",
      sortable: false,
      cell: (row) => <div>{datetimeToString(row.endTime)}</div>,
    },
  ]);

  useEffect(() => {
    var isCancelled = false;
    if (!isCancelled) {
      childRef.current.setFilter({ searchBox: "" });
    }
    return () => {
      isCancelled = true;
    };
  }, []);
  return (
    <section className="content">
      <div className="container-fluid">
        <div className="row mt-2 mb-2">
          <div className="col-12">
            <div className="card">
              <div className="card-header">
                <h3 className="card-title">Message</h3>
                {/* <div className="card-tools">
                  <div className="input-group input-group-sm">
                    <input type="text" className="form-control" />
                    <span className="input-group-append">
                      <button
                        type="button"
                        onClick={searchOnClick}
                        className="btn btn-info btn-flat mr-1"
                        style={{ backgroundColor: "#869099" }}
                      >
                        Search!
                      </button>
                    </span>
                  </div>
                </div> */}
              </div>
              {/* /.card-header */}
              <div className="card-body">
                <AdvancedPaginationTable
                  ref={childRef}
                  title=""
                  columns={columns}
                  apiURL="\api\messages"
                  isSelectableRows={false}
                  noHeader={true}
                />
              </div>
              {/* /.card-body */}
            </div>
          </div>
          {/* /.col */}
        </div>
        {/* /.row */}
      </div>
      {/* /.container-fluid */}
    </section>
  );
}
