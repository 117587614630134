import { Center, SimpleGrid } from "@mantine/core";
import React, { useEffect, useState } from "react";
import ClassCard from "../../../../@core/components/ClassCard";
import BookingService from "../../../../services/process/BookingService";

const Schedule = () => {
  const [data, setData] = useState(null);
  async function fetchSchedule() {
    const handler = new BookingService();
    const response = await handler.getSchedule();

    console.log(response.data);
    setData(response.data);
  }
  useEffect(() => {
    fetchSchedule();
  }, []);

  return (
    // <Center>
    <SimpleGrid cols={4}>
      {data &&
        data.map((item, index) => {
          return <ClassCard key={`${index}`} item={item} />;
        })}
    </SimpleGrid>
    // </Center>
  );
};

export default Schedule;
