import React, { useMemo, useRef, useEffect, useState } from "react";
import Swal from "sweetalert2/dist/sweetalert2";
import AdvancedPaginationTable from "../../../@core/components/AdvancedPaginationTable";
import { customFormatDateTime } from "../../../@core/utilities/index";
import { AppConfig } from "../../../AppConfig";
import TodayClassService from "../../../services/process/TodayClassService";
import { Button } from "@mantine/core";
import { IconCirclePlus, IconCalendarTime } from "@tabler/icons";
import { DatePicker } from "@mantine/dates";
import DateInput from "../../../@core/components/DateInput";
export default function TodayClassList(props) {
  const [searchBox, setSearchBox] = useState("");
  const [searchDate, setSearchDate] = useState(null);
  const childRef = useRef();
  const columns = useMemo(() => [
    {
      name: "Date",
      sortable: false,
      cell: (row) => <div>{customFormatDateTime(row.date, `DD/MM/YYYY`)}</div>,
    },
    {
      name: "Course Name",
      selector: "courseName",
      sortable: false,
    },

    {
      name: "Course Time",
      selector: "courseTime",
      sortable: false,
    },
    {
      name: "Instructor",
      sortable: false,
      cell: (row) => (
        <div>
          {row.firstName} {row.lastName}
        </div>
      ),
    },
    {
      cell: (row) => {
        return (
          <div>
            <a
              href="#empty"
              onClick={(e) => {
                e.preventDefault();
                editOnclick(row);
              }}
            >
              <i className="fas fa-pencil-alt mr-3"></i>
            </a>
            <a
              href="#empty"
              onClick={(e) => {
                e.preventDefault();
                deleteOnClick(row);
              }}
            >
              <i className="fas fa-trash-alt"></i>
            </a>
          </div>
        );
      },
    },
  ]);
  const fnCallAPIDeleteData = async (id) => {
    const handler = new TodayClassService();
    try {
      const result = await handler.delete(id);
      if (result !== null) {
        if (result.status === `00`) {
          Swal.close();
          Swal.fire({
            icon: `info`,
            title: `Deleted`,
            html: `Your file has been deleted.`,
          }).then(() => {
            // props.history.push(`${AppConfig.applicationPath}/todayclass`);
            childRef.current.setFilter({ searchBox });
          });
        }
      }
    } catch (error) {
      console.log("error");
      Swal.fire("Deleted!", "Delete fail.", "error");
    }
  };
  const newOnclick = () => {
    props.history.push(`${AppConfig.applicationPath}/todayclass/new`);
  };
  const editOnclick = (row) => {
    props.history.push(
      `${AppConfig.applicationPath}/todayclass/edit/${row.id}`
    );
  };
  const deleteOnClick = (row) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Are you sure you want to delete this information?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Please Wait !",
          html: "System processing", // add html attribute if you want or remove
          allowOutsideClick: false,
          showCancelButton: false,
          showConfirmButton: false,
          willOpen: async () => {
            Swal.showLoading();
            try {
              await fnCallAPIDeleteData(row.id);
            } catch (error) {
              console.log("error");
            }
          },
        });
      }
    });
  };
  const generateOnclick = async () => {
    Swal.fire({
      title: "Are you sure?",
      text: "Are you sure you want to generate today classs?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, generate it!",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Please Wait !",
          html: "System processing", // add html attribute if you want or remove
          allowOutsideClick: false,
          showCancelButton: false,
          showConfirmButton: false,
          willOpen: async () => {
            Swal.showLoading();
            try {
              const handler = new TodayClassService();
              const result = await handler.generate();
              console.log(result);
              if (result !== null) {
                if (result.status === `00`) {
                  Swal.fire({
                    icon: `info`,
                    title: `success`,
                    html: `Generate data success.`,
                  }).then(() => {
                    // props.history.push(
                    //   `${AppConfig.applicationPath}/todayclass`
                    // );
                    childRef.current.setFilter({ searchBox });
                  });
                }
              }
            } catch (error) {
              Swal.close();
              console.log("error");
              Swal.fire("Generate!", "Generate fail.", "error");
            }
          },
        });
      }
    });
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      childRef.current.doSearchCommon("test");
    }
  };
  const onDateChange = (newDate) => {
    //Your custom code here
    setSearchDate(newDate);
    const searchvalue = customFormatDateTime(newDate, `YYYY-MM-DD`);

    const dataSubmit = {
      searchBox: searchvalue,
    };
    childRef.current.doSearchCommon(dataSubmit);
  };
  useEffect(() => {
    let isCancelled = false;
    if (!isCancelled) {
      childRef.current.setFilter({ searchBox: "" });
    }
    return () => {
      isCancelled = true;
    };
  }, []);
  return (
    <section className="content">
      <div className="container-fluid">
        <div className="row mt-2 mb-2">
          <div className="col-12">
            <div className="card">
              <div className="card-header">
                <h3 className="card-title">Today Class</h3>
                <div className="card-tools">
                  <DateInput
                    placeholder="Select Date"
                    value={searchDate}
                    onChange={onDateChange}
                  />
                </div>
              </div>
              {/* /.card-header */}
              <div className="card-body">
                <Button
                  leftIcon={<IconCirclePlus />}
                  onClick={newOnclick}
                  color="blue"
                >
                  New
                </Button>
                <Button
                  leftIcon={<IconCalendarTime />}
                  onClick={generateOnclick}
                  style={{ marginLeft: 5 }}
                  color="teal"
                >
                  Generate
                </Button>

                <AdvancedPaginationTable
                  ref={childRef}
                  title="Today Class"
                  columns={columns}
                  apiURL="/api/todayClasses"
                  isSelectableRows={false}
                  noHeader={true}
                  showExport={false}
                />
              </div>
              {/* /.card-body */}
            </div>
          </div>
          {/* /.col */}
        </div>
        {/* /.row */}
      </div>
      {/* /.container-fluid */}
    </section>
  );
}
