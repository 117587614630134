import React, { useMemo, useRef, useEffect, useState } from "react";
import Swal from "sweetalert2/dist/sweetalert2";
import AdvancedPaginationTable from "../../../@core/components/AdvancedPaginationTable";
import { customFormatDateTime } from "../../../@core/utilities/index";
import { AppConfig } from "../../../AppConfig";
import BookingService from "../../../services/process/BookingService";
// import DatePicker from "react-datepicker";
import {
  Box,
  Button,
  Divider,
  Group,
  Tabs,
  Text,
  TextInput,
} from "@mantine/core";
import {
  IconCirclePlus,
  IconClock2,
  IconId,
  IconRotateClockwise,
  IconSearch,
  IconUser,
} from "@tabler/icons";
import { useDebouncedState } from "@mantine/hooks";
import DateInput from "../../../@core/components/DateInput";
import TodaySchedule from "./TodaySchedule/TodaySchedule";
import Schedule from "./Schedule/Schedule";
export default function BookingList(props) {
  const [searchDate, setSearchDate] = useState(new Date());
  const [searchCode, setSearchCode] = useDebouncedState("", 200);
  const [searchNickname, setSearchNickname] = useDebouncedState("", 200);

  const childRef = useRef();
  const columns = useMemo(() => [
    {
      name: "Date",
      sortable: false,
      cell: (row) => <div>{customFormatDateTime(row.date, `DD/MM/YYYY`)}</div>,
    },
    {
      name: "Course Name",
      selector: "courseName",
      sortable: false,
    },
    {
      name: "Instructor Code",
      selector: "instructorCode",
      sortable: false,
    },
    {
      name: "Instructor Nickname",
      selector: "instructorNickname",
      sortable: false,
    },
    {
      name: "Instructor",
      sortable: false,
      cell: (row) => (
        <div>
          {row.instructorFirstName} {row.instructorLastName}
        </div>
      ),
    },
    {
      name: "Student Code",
      selector: "studentCode",
      sortable: false,
    },
    {
      name: "Student Nickname",
      selector: "studentNickname",
      sortable: false,
    },
    {
      name: "Student",
      sortable: false,
      cell: (row) => (
        <div>
          {row.studentFirstName} {row.studentLastName}
        </div>
      ),
    },
    {
      name: "Start Date",
      sortable: false,
      cell: (row) => (
        <div>
          {customFormatDateTime(row.courseStartTime, `DD/MM/YYYY HH:MM`)}
        </div>
      ),
    },
    {
      name: "End Date",
      sortable: false,
      cell: (row) => (
        <div>{customFormatDateTime(row.courseEndTime, `DD/MM/YYYY HH:MM`)}</div>
      ),
    },
    {
      cell: (row) => {
        return (
          <div>
            <a
              href="#empty"
              onClick={(e) => {
                e.preventDefault();
                editOnclick(row);
              }}
            >
              <i className="fas fa-pencil-alt mr-3"></i>
            </a>
            <a
              href="#empty"
              onClick={(e) => {
                e.preventDefault();
                deleteOnClick(row);
              }}
            >
              <i className="fas fa-trash-alt"></i>
            </a>
          </div>
        );
      },
    },
  ]);
  const getFilter = () => {
    const searchDateValue = customFormatDateTime(searchDate, `YYYY-MM-DD`);

    return {
      date: searchDateValue,
      studentCode: searchCode,
      studentNickname: searchNickname,
    };
  };
  const fnCallAPIDeleteData = async (id) => {
    const handler = new BookingService();
    try {
      const result = await handler.delete(id);
      if (result !== null) {
        if (result.status === `00`) {
          Swal.close();
          Swal.fire({
            icon: `info`,
            title: `Deleted`,
            html: `Your file has been deleted.`,
          }).then(() => {
            props.history.push(`${AppConfig.applicationPath}/booking`);
          });
        }
      }
    } catch (error) {
      console.log("error");
      Swal.fire("Deleted!", "Delete fail.", "error");
    }
  };
  const newOnclick = () => {
    props.history.push(`${AppConfig.applicationPath}/booking/new`);
  };
  const editOnclick = (row) => {
    props.history.push(`${AppConfig.applicationPath}/booking/edit/${row.id}`);
  };
  const searchOnclick = (row) => {
    const searchDateValue = customFormatDateTime(searchDate, `YYYY-MM-DD`);
    console.log(searchDateValue);
    const dataSubmit = {
      date: searchDateValue,
      studentCode: searchCode,
      studentNickname: searchNickname,
    };
    childRef.current.setFilter(dataSubmit);
  };
  const deleteOnClick = (row) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Are you sure you want to delete this information?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Please Wait !",
          html: "System processing", // add html attribute if you want or remove
          allowOutsideClick: false,
          showCancelButton: false,
          showConfirmButton: false,
          willOpen: async () => {
            Swal.showLoading();
            try {
              await fnCallAPIDeleteData(row.id);
            } catch (error) {
              console.log("error");
            }
            childRef.current.setFilter(getFilter());
          },
        });
      }
    });
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
    }
  };

  useEffect(() => {
    let isCancelled = false;
    if (!isCancelled) {
      // const searchDateValue = customFormatDateTime(searchDate, `YYYY-MM-DD`);
      // const dataSubmit = {
      //   date: searchDateValue,
      //   studentCode: searchCode,
      //   studentNickname: searchNickname,
      // };

      // childRef.current.setFilter(dataSubmit);
      childRef.current.setFilter(getFilter());
    }
    return () => {
      isCancelled = true;
    };
  }, [searchDate, searchCode, searchNickname]);

  return (
    <section className="content">
      <div className="container-fluid">
        <div className="row mt-2 mb-2">
          <div className="col-12">
            <div className="card">
              <div className="card-header">
                <h3 className="card-title">Booking</h3>
              </div>
              {/* /.card-header */}
              <div className="card-body">
                <div
                  className="form-row align-items-center"
                  style={{ alignItems: "flex-end !important" }}
                >
                  <Group mb={10} ml={5}>
                    <TextInput
                      icon={<IconId />}
                      defaultValue={searchCode}
                      onChange={(e) => setSearchCode(e.target.value)}
                      placeholder="Student Code"
                    />
                    <TextInput
                      icon={<IconUser />}
                      defaultValue={searchNickname}
                      onChange={(e) => setSearchNickname(e.target.value)}
                      placeholder="Student Nickname"
                    />
                    <DateInput
                      value={searchDate}
                      onChange={setSearchDate}
                      clearable={false}
                    />
                    {/* <Button leftIcon={<IconSearch />} onClick={searchOnclick}>
                      Apply Filter
                    </Button> */}
                  </Group>

                  {/* <div className="col-auto">
                    
                    <label>Student Code</label>
                    <input
                      type="text"
                      className="form-control"
                      value={searchCode}
                      onChange={(e) => setSearchCode(e.target.value)}
                    />
                  </div> */}
                  {/* <div className="col-auto">
                    <label>Student Nickname</label>
                    <input
                      type="text"
                      className="form-control"
                      value={searchNickname}
                      onChange={(e) => setSearchNickname(e.target.value)}
                    />
                  </div> */}

                  {/* <div className="col-auto">
                    <label>Date</label>
                    <DatePicker
                      name="searchDate"
                      selected={searchDate}
                      dateFormat="yyyy-MM-dd"
                      className="form-control"
                      onChange={(date) => setSearchDate(date)}
                    />
                  </div> */}
                </div>
                <Button
                  leftIcon={<IconCirclePlus />}
                  onClick={newOnclick}
                  color="blue"
                >
                  New
                </Button>
                {/* <div style={{ marginTop: 10 }}>
                  <Button leftIcon={<IconSearch />} onClick={searchOnclick}>
                    Apply Filter
                  </Button>
                  <Button
                    leftIcon={<IconCirclePlus />}
                    onClick={newOnclick}
                    style={{ marginLeft: 5 }}
                  >
                    New
                  </Button>
                </div> */}
                {/* <Divider mt={10} mb={5} /> */}
                <Tabs defaultValue="today">
                  <Tabs.List>
                    <Tabs.Tab value="today" icon={<IconClock2 size={14} />}>
                      Today
                    </Tabs.Tab>
                    <Tabs.Tab
                      value="all"
                      icon={<IconRotateClockwise size={14} />}
                    >
                      All
                    </Tabs.Tab>
                  </Tabs.List>
                  <Tabs.Panel value="today" pt="xs">
                    <Box>
                      <TodaySchedule date={searchDate} />
                    </Box>
                  </Tabs.Panel>

                  <Tabs.Panel value="all" pt="xs">
                    <Box>
                      <Schedule />
                    </Box>
                  </Tabs.Panel>
                </Tabs>

                <Divider mt={20} mb={10} />
                <AdvancedPaginationTable
                  ref={childRef}
                  title="Booking"
                  columns={columns}
                  apiURL="/api/booking"
                  isSelectableRows={false}
                  noHeader={true}
                  showExport={false}
                />
              </div>
              {/* /.card-body */}
            </div>
          </div>
          {/* /.col */}
        </div>
        {/* /.row */}
      </div>
      {/* /.container-fluid */}
    </section>
  );
}
