import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import Select from "react-select";
import { useForm } from "react-hook-form";
import UserService from "../../../services/master/UserService";
import { useParams } from "react-router";
import {
  isNullEmpty,
  handleTextNumberKeyDownCommon,
} from "../../../@core/utilities";
import { getUserId } from "../../../@core/services";
// import { getToken } from "../../../@core/services";
import { format } from "date-fns";
import Swal from "sweetalert2";
import { AppConfig } from "../../../AppConfig";
import axios from "axios";
import Label from "../../../@core/components/Label";
import "react-datepicker/dist/react-datepicker.css";
import { Container, Center, Popover, Button, Text } from "@mantine/core";
import InstructorProfileForm from "./InstructorProfileForm";
import { IconLock } from "@tabler/icons";
export default function UserManage(props) {
  const { id } = useParams();
  const [method, setMethod] = useState("NEW");
  const {
    register,
    handleSubmit,
    setValue,
    clearErrors,
    getValues,
    formState: { errors },
  } = useForm();
  const [roleDefault, setRoleDefault] = useState();
  const [rolesOptions, setRolesOptions] = useState(null);
  const [userNameValue, setUserNameValue] = useState("");
  const [roleSelected, setRoleSelcted] = useState("");
  const [imagePath, setImagePath] = useState("");
  const [isStudent, setIsStudent] = useState(false);
  const [isInstructor, setIsInstructor] = useState(false);
  const [birthDate, setBirthDate] = useState(new Date());
  const [sex, setSex] = useState(null);
  const [howtoKnowValue, setHowtoKnowValue] = useState(null);
  const [openEditor, setOpenEditor] = useState(false);
  const [{ alt, src }, setImagePreview] = useState({
    src: "https://mdbootstrap.com/img/Photos/Others/placeholder.jpg",
    alt: "Upload an Image",
  });
  const [validateUsercode, setValidateUsercode] = useState(false);

  const howtoKnowOption = [
    {
      value: "Acquaintance recommendations",
      label: "Acquaintance recommendations",
    },
    { value: "Social Media", label: "Social Media" },
  ];
  const sexOption = [
    { value: "Male", label: "Male" },
    { value: "Female", label: "Female" },
  ];
  var messageError = "";
  useEffect(() => {
    async function fetchData() {
      await getRolesOption();
      const handler = new UserService();
      const objinfo = await handler.get(id);
      if (objinfo !== null) {
        setValue("code", objinfo.code);
        setValue("userName", objinfo.username);
        setValue("passwordOfUser", objinfo.password);
        setValue("confirmPasswordOfUser", objinfo.password);
        setValue("firstName", objinfo.firstName);
        setValue("lastName", objinfo.lastName);
        setValue("nickName", objinfo.nickname);
        setRoleSelcted(objinfo.roleId);
        setRoleDefault({
          value: objinfo.roleId,
          label: objinfo.roleName,
        });
        setSex(objinfo.gender);
        setValue("sex", objinfo.gender);

        setHowtoKnowValue(objinfo.questionKnowHarlem);
        setValue("howtoKnow", objinfo.questionKnowHarlem);
        setValue("emailOfUser", objinfo.email);
        setValue("idcard", objinfo.idCard);
        setValue("weight", objinfo.weight);
        setValue("height", objinfo.height);
        setValue("occupation", objinfo.occupation);
        setValue("school", objinfo.school);
        setValue("contactNo", objinfo.contactNo);
        setImagePath(objinfo.imagePath);
        setBirthDate(new Date(objinfo.dateOfBirth));
        setValue("address", objinfo.address);
        setValue("remark", objinfo.remark);
        setValue("questionObjective", objinfo.questionObjective);
        setValue("profile", objinfo.profile);
        setValidateUsercode(true);
        setValue("roleSelected", objinfo.roleId);

        setImagePreview({
          src: objinfo.imagePath,
          alt: "",
        });

        if (objinfo.roleName === "Student") {
          setIsStudent(true);
          setIsInstructor(false);
        } else if (objinfo.roleName === "Instructor") {
          setIsInstructor(true);
          setIsStudent(false);
        } else {
          setIsInstructor(false);
          setIsStudent(false);
        }
      } else {
        Swal.fire({
          title: "Error!",
          text: "Data not found",
          icon: "error",
          confirmButtonText: "Ok",
        });
        props.history.push(`${AppConfig.applicationPath}/users`);
      }
    }
    if (!isNullEmpty(id)) {
      setMethod("EDIT");
      Swal.fire({
        title: "Please Wait !",
        html: "System processing", // add html attribute if you want or remove
        allowOutsideClick: false,
        showCancelButton: false,
        showConfirmButton: false,
        willOpen: () => {
          Swal.showLoading();
          fetchData();
          Swal.close();
        },
      });
    } else {
      getRolesOption();
    }
  }, []);

  const backOnClick = () => {
    props.history.push(`${AppConfig.applicationPath}/users`);
  };
  const validateUsername_OnClick = async () => {
    Swal.fire({
      title: "Please Wait !",
      html: "System processing", // add html attribute if you want or remove
      allowOutsideClick: false,
      showCancelButton: false,
      showConfirmButton: false,
      willOpen: async () => {
        Swal.showLoading();
        try {
          const handler = new UserService();
          const result = await handler.checkExistsUserName(userNameValue);
          Swal.close();
          if (result) {
            setValue("userName", "");
            setValidateUsercode(false);
            Swal.fire({
              title: "Error!",
              text: "Username is exists",
              icon: "error",
              confirmButtonText: "Ok",
            });
          } else {
            setValidateUsercode(true);
          }
          console.log(result);
        } catch (error) {
          console.log("error");
        }
      },
    });
  };
  const validateButtonOnClick = () => {
    //console.log("test");
    //validateUsercode = true;
    setValidateUsercode(true);
  };
  const getRolesOption = async () => {
    const handler = new UserService();
    const data = await handler.getRoles();
    if (data !== undefined) {
      const options = data.map((d) => ({
        value: d.id,
        label: d.name,
      }));
      setRolesOptions(options);
    } else {
      setRolesOptions(null);
    }
  };
  const handleRole_OnChange = (e) => {
    //console.log(e);
    //setRoleDefault(e.value);
    if (e.label === "Student") {
      setIsStudent(true);
      setIsInstructor(false);
    } else if (e.label === "Instructor") {
      setIsInstructor(true);
      setIsStudent(false);
    } else {
      setIsInstructor(false);
      setIsStudent(false);
    }
    setRoleSelcted(e.value);
    setRoleDefault({
      value: e.value,
      label: e.label,
    });
    setValue("roleSelected", e.value);
    clearErrors("roleSelected");
  };
  const handleSex_OnChange = (e) => {
    setSex(e.value);
    setValue("sex", e.value);
    clearErrors("sex");
  };
  const handleUserName_OnChange = (e) => {
    setValidateUsercode(false);
    setUserNameValue(e.target.value);
  };
  const handleHowto_OnChange = (e) => {
    setHowtoKnowValue(e.value);
    setValue("howtoKnow", e.value);
    clearErrors("howtoKnow");
  };
  const handleChangeFile = async (e) => {
    if (e.target.files[0]) {
      const file = e.target.files[0];
      const data = new FormData();
      data.append("filetoupload", file);
      var config = { headers: { Authorization: "" } };
      const urlAPI = `${AppConfig.apiURL}/api/users/uploadimage`;
      await import("../../../@core/services").then((module) => {
        config = {
          headers: {
            Authorization: "Bearer " + module.getToken(),
          },
        };
      });
      // const config = {
      //   headers: {
      //     Authorization: "Bearer " + getToken(),
      //   },
      // };
      axios
        .post(urlAPI, data, config)
        .then((res) => {
          // then print response status

          console.warn(res);
          setImagePath(res.data);
          setImagePreview({
            src: URL.createObjectURL(e.target.files[0]),
            alt: e.target.files[0].name,
          });
        })
        .catch((error) => {
          if (error.response.status === 401) {
            props.history.push(`${AppConfig.loginPath}`);
          }
        });
    }
  };
  const validateData = (data) => {
    let result = true;
    if (!validateUsercode) {
      result = false;
      messageError += "please put validate button.<br/>";
    }
    if (data.passwordOfUser !== data.confirmPasswordOfUser) {
      result = false;
      messageError += "password not equal confirm password<br/>";
    }

    return result;
  };
  const onError = (errors, e) => console.log(errors, e);
  const onSubmit = async (data) => {
    if (!validateData(data)) {
      Swal.fire({
        title: "Error!",
        html: messageError,
        icon: "error",
        confirmButtonText: "Ok",
      });
      return false;
    }

    const birthDateValue = format(birthDate, "yyyy-MM-dd");
    const handler = new UserService();
    let result = null;
    const dataSubmit = {
      id: id,
      code: data.code,
      username: data.userName,
      password: data.passwordOfUser,
      firstname: data.firstName,
      lastname: data.lastName,
      nickname: data.nickName,
      dob: birthDateValue,
      gender: sex,
      weight: data.weight,
      height: data.height,
      occupation: data.occupation,
      address: data.address,
      school: data.school,
      contactNo: data.contactNo,
      questionKnowHarlem: howtoKnowValue,
      questionObjective: data.questionObjective,
      idcard: data.idcard,
      remark: data.remark,
      isStudent: isStudent,
      isInstructor: isInstructor,
      email: data.emailOfUser,
      imagePath: imagePath,
      profile: data.profile,
      roleId: roleSelected,
      userId: getUserId(),
    };

    if (method === "NEW") {
      console.log("NEW");
      result = await handler.insert(dataSubmit);
    } else if (method === "EDIT") {
      console.log("Update");
      console.log(dataSubmit);
      result = await handler.update(dataSubmit);
    }
    if (result !== null) {
      if (result.status === "00") {
        Swal.fire({
          icon: "info",
          title: "success",
          html: "Save data success.",
        }).then(() => {
          props.history.push(`${AppConfig.applicationPath}/users`);
        });
      }
    }
  };
  return (
    <Container size="lg">
      <Center style={{ paddingTop: 10 }}>
        <div className="row" style={{ marginBottom: 130 }}>
          <div className="col-12">
            <form onSubmit={handleSubmit(onSubmit, onError)}>
              <div className="card card-outline">
                <div className="card-header">
                  <h3 className="card-title">
                    <i className="fas fa-edit" />
                    User
                  </h3>
                </div>
                <div className="card-body" style={{ paddingTop: 5 }}>
                  <div className="row">
                    <div className="col-md-8">
                      <div className="row">
                        <div
                          className="col-md-4"
                          style={{ display: method === "NEW" ? "none" : "" }}
                        >
                          <div className="form-group">
                            <Label text="Code" required />
                            <input
                              type="text"
                              name="code"
                              className="form-control"
                              ref={register()}
                              readOnly
                            />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <Label text="Username" required />
                            <div className="form-inline">
                              <input
                                type="text"
                                name="userName"
                                className={
                                  validateUsercode === true
                                    ? "form-control is-valid mr-1"
                                    : "form-control mr-1"
                                }
                                style={{ width: "70%" }}
                                ref={register()}
                                required
                                onChange={handleUserName_OnChange}
                              />
                              {errors.userName && (
                                <span>This field is required</span>
                              )}
                              <button
                                type="button"
                                className="btn btn-default"
                                onClick={validateUsername_OnClick}
                                disabled={
                                  validateUsercode === true ? true : false
                                }
                              >
                                Validate
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <Label text="Password" required />
                            <input
                              type="password"
                              name="passwordOfUser"
                              className="form-control"
                              ref={register()}
                              required
                            />
                            {errors.passwordOfUser && (
                              <span>This field is required</span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <Label text="Confirm Password" required />
                            <input
                              type="password"
                              name="confirmPasswordOfUser"
                              className="form-control"
                              ref={register()}
                            />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <Label text="First name" required />
                            <input
                              type="text"
                              name="firstName"
                              className="form-control"
                              ref={register()}
                              required
                            />
                            {errors.firstName && (
                              <span>This field is required</span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <Label text="Last name" required />
                            <input
                              type="text"
                              name="lastName"
                              className="form-control"
                              ref={register()}
                              required
                            />
                            {errors.lastName && (
                              <span>This field is required</span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <Label text="Nickname" required />
                            <input
                              type="text"
                              name="nickName"
                              className="form-control"
                              ref={register()}
                              required
                            />
                            {errors.nickName && (
                              <span>This field is required</span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <Label text="Role" required />
                            <Select
                              name="roleSelected"
                              value={roleDefault}
                              options={rolesOptions}
                              onChange={handleRole_OnChange.bind(this)}
                              ref={register("roleSelected", { required: true })}
                            />
                            {errors.roleSelected && (
                              <span>This field is required</span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <Label text="Email" required />
                            <input
                              type="email"
                              name="emailOfUser"
                              className="form-control"
                              ref={register()}
                              required
                            />
                            {errors.emailOfUser && (
                              <span>This field is required</span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <Label text="ID Number" required />
                          <input
                            type="text"
                            name="idcard"
                            className="form-control"
                            ref={register({ maxLength: 13 })}
                            required
                            maxLength="13"
                          />
                          {errors.idcard && <span>This field is required</span>}
                        </div>
                        <div className="col-md-4">
                          <Label text="Weight(kg)" required />
                          <input
                            type="text"
                            name="weight"
                            className="form-control"
                            ref={register()}
                            required
                            onKeyDown={handleTextNumberKeyDownCommon}
                          />
                          {errors.weight && <span>This field is required</span>}
                        </div>
                        <div className="col-md-4">
                          <Label text="Height(cm)" required />
                          <input
                            type="text"
                            name="height"
                            className="form-control"
                            ref={register()}
                            required
                            onKeyDown={handleTextNumberKeyDownCommon}
                          />
                          {errors.height && <span>This field is required</span>}
                        </div>
                        <div className="col-md-4">
                          <Label text="Occupation" required />
                          <input
                            type="text"
                            name="occupation"
                            className="form-control"
                            ref={register()}
                            required
                            maxLength="50"
                          />
                          {errors.occupation && (
                            <span>This field is required</span>
                          )}
                        </div>
                        <div className="col-md-4">
                          <Label text="School" required />
                          <input
                            type="text"
                            name="school"
                            className="form-control"
                            ref={register()}
                            required
                            maxLength="50"
                          />
                          {errors.school && <span>This field is required</span>}
                        </div>
                        <div className="col-md-4">
                          <Label text="Contact number" required />
                          <input
                            type="text"
                            name="contactNo"
                            className="form-control"
                            ref={register()}
                            required
                            maxLength="30"
                          />
                          {errors.contactNo && (
                            <span>This field is required</span>
                          )}
                        </div>
                        <div className="col-md-4">
                          <Label text="Birth date" required />
                          <div className="form-group">
                            <DatePicker
                              name="dob"
                              selected={birthDate}
                              dateFormat="dd/MM/yyyy"
                              className="form-control"
                              onChange={(date) => setBirthDate(date)}
                            />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <Label text="Gender" required />
                          <Select
                            name="sex"
                            value={sexOption.filter(
                              (option) => option.value === sex
                            )}
                            options={sexOption}
                            ref={register("sex", { required: true })}
                            onChange={handleSex_OnChange.bind(this)}
                          />
                          {errors.sex && <span>This field is required</span>}
                        </div>
                        <div className="col-md-8">
                          <Label
                            text="
                        From which channel did you come to know about Harlem
                        Shake?
                      "
                            required
                          />
                          <Select
                            name="howtoKnow"
                            value={howtoKnowOption.filter(
                              (option) => option.value === howtoKnowValue
                            )}
                            options={howtoKnowOption}
                            ref={register("howtoKnow", { required: true })}
                            onChange={handleHowto_OnChange.bind(this)}
                          />
                          {errors.howtoKnow && (
                            <span>This field is required</span>
                          )}
                        </div>

                        <div className="col-md-12">
                          <Label text="Address" required />
                          <textarea
                            className="form-control"
                            name="address"
                            defaultValue={""}
                            ref={register()}
                            required
                          />
                          {errors.address && (
                            <span>This field is required</span>
                          )}
                        </div>
                        <div className="col-md-12">
                          <Label
                            text="
                        What are the goals you expected to obtain by attending
                        classes here?
                      "
                            required
                          />
                          <textarea
                            className="form-control"
                            name="questionObjective"
                            defaultValue={""}
                            ref={register()}
                            required
                          />
                          {errors.questionObjective && (
                            <span>This field is required</span>
                          )}
                        </div>
                        <div className="col-md-12">
                          <Label text="Remark" />
                          <textarea
                            className="form-control"
                            name="remark"
                            defaultValue={""}
                            ref={register()}
                          />
                        </div>
                        <div className="col-md-12">
                          <div className="form-group">
                            <Label text="Profile" required />
                            <textarea
                              className="form-control"
                              name="profile"
                              defaultValue={""}
                              ref={register()}
                              required
                              // disabled={roleSelected === 3}
                              readOnly={roleSelected === 3}
                            />
                            {errors.profile && (
                              <span>This field is required</span>
                            )}
                          </div>
                        </div>
                        {roleSelected === 3 && (
                          <div className="col-md-12">
                            <Popover
                              width={200}
                              position="bottom"
                              withArrow
                              shadow="md"
                              width={500}
                              opened={openEditor}
                              onClose={() => setOpenEditor(false)}
                            >
                              <Popover.Target>
                                <Button
                                  leftIcon={<IconLock />}
                                  onClick={() => setOpenEditor(true)}
                                >
                                  Edit Instructor Porfile
                                </Button>
                              </Popover.Target>
                              <Popover.Dropdown>
                                <InstructorProfileForm
                                  getDefault={() => getValues("profile")}
                                  setProfile={(str) => setValue("profile", str)}
                                  onClose={() => setOpenEditor(false)}
                                />
                              </Popover.Dropdown>
                            </Popover>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="row">
                        <div className="form-group col-md-12">
                          <div className="z-depth-1-half mb-5">
                            <div className="card">
                              <div className="card-img-top">
                                <div className="productImage">
                                  <div className="img-wrapper">
                                    <a className="thumb" href="#">
                                      <img src={src} alt={alt} />
                                    </a>
                                  </div>
                                </div>
                              </div>
                              <div className="card-footer">
                                <div className="custom-file">
                                  <input
                                    id="fileInput"
                                    type="file"
                                    className="custom-file-input"
                                    accept="image/x-png,image/gif,image/jpeg"
                                    onChange={handleChangeFile}
                                  />
                                  <label
                                    className="custom-file-label"
                                    htmlFor="customInput"
                                  >
                                    Choose file...
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-footer">
                  <button type="submit" className="btn btn-default mr-1">
                    Save
                  </button>
                  <button
                    type="button"
                    className="btn btn-default"
                    onClick={backOnClick}
                  >
                    Back
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Center>
    </Container>
  );
}
