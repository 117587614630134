import React, {
  useMemo,
  useRef,
  useEffect,
  useState,
  useCallback,
} from "react";
import Swal from "sweetalert2/dist/sweetalert2";
import AdvancedPaginationTable from "../../../@core/components/AdvancedPaginationTable";
import { formatUTCTime, toCSVHeader } from "../../../@core/utilities";
import { AppConfig } from "../../../AppConfig";
import CourseService from "../../../services/master/CourseService";
import axios from "axios";
import { getToken } from "../../../@core/services";
import {
  Badge,
  Box,
  Button,
  Center,
  Checkbox,
  Container,
  Group,
  Stack,
  Switch,
  Tabs,
  Text,
  TextInput,
} from "@mantine/core";
import { IconCalendar, IconCirclePlus, IconList } from "@tabler/icons";
import { useDebouncedState } from "@mantine/hooks";
import SearchBox from "../../../@core/components/SearchBox";
import Timetable from "./Timetable/Timetable";

const ExpireBadge = () => {
  return <Badge color="yellow">Expired</Badge>;
};

export default function CoursesList(props) {
  const childRef = useRef();
  const [searchBox, setSearchBox] = useDebouncedState("", 200);
  const [courseTypeOptions, setCourseTypeOptions] = useState(null);
  const [showExpired, setShowExpired] = useState(false);
  const [expand, setExpand] = useState(false);
  const [filter, setFilter] = useState({ expand: false, expired: false });
  const getCourseTypeName = useCallback((id) => {
    if (courseTypeOptions === null) return null;
    else return courseTypeOptions.filter((c) => c.id === id)[0].name;
  });
  const currentDate = new Date();
  const enableTime = (courseType) => {
    if (courseType != null && courseType != undefined) {
      var lower = courseType.toLowerCase();
      return lower === "open" || lower === "one" || lower === "trial";
    }
    return true;
  };
  const fetchCourseType = async () => {
    const config = {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    };
    const urlAPI = `${AppConfig.apiURL}/api/courseTypes?page=${1}&size=${100}`;
    const response = await axios.post(urlAPI, null, config);
    setCourseTypeOptions(response.data.data.rows);
  };
  useEffect(() => {
    var isCancelled = false;
    if (!isCancelled) {
      fetchCourseType();
    }
    childRef.current.setHeader(toCSVHeader(csvCols));
    return () => {};
  }, []);
  useEffect(() => {
    var isCancelled = false;
    if (!isCancelled) {
      childRef.current.setFilter({ searchBox, expired: filter.expired });
    }
    return () => {
      isCancelled = true;
    };
  }, [searchBox, filter]);

  const csvCols = useMemo(() => [
    {
      name: "Course Name",
      selector: "courseName",
    },
    {
      name: "Course Type",
      selector: "courseTypeName",
    },
    {
      name: "Start Course",
      selector: "startCourse",
    },
    {
      name: "End Course",
      selector: "endCourse",
    },

    {
      name: "Day Of Week",
      selector: "dayOfWeek",
    },
  ]);
  const columns = useMemo(() => [
    {
      name: "Course Name",

      grow: 2,
      cell: (row) => {
        const endDate = new Date(row.endCourse);
        if (endDate <= currentDate) {
          return (
            <Group spacing="xs" position="left">
              <Text>{row.courseName}</Text> <ExpireBadge />
            </Group>
          );
        } else return <Text>{row.courseName}</Text>;
      },
    },

    {
      name: "Course Type",
      selector: "courseTypeName",
      sortable: false,
    },
    { name: "Instructor", selector: "instructorFirstName", sortable: false },

    {
      name: "Start Course",
      sortable: false,
      cell: (row) => {
        return row.startCourse === null ||
          enableTime(getCourseTypeName(row.courseTypeId)) ? (
          <div>{formatUTCTime(row.startCourse)}</div>
        ) : (
          <span>-</span>
        );
      },
    },
    {
      name: "End Course",
      sortable: false,
      cell: (row) =>
        row.endCourse === null ||
        enableTime(getCourseTypeName(row.courseTypeId)) ? (
          <div>{formatUTCTime(row.endCourse)}</div>
        ) : (
          <span>-</span>
        ),
    },

    {
      name: "Day Of Week",
      selector: "dayOfWeek",
      sortable: false,
    },

    {
      cell: (row) => {
        if (row === undefined) return <></>;
        return row.courseTypeName.toLowerCase() === "one" ||
          row.courseTypeName.toLowerCase() === "trial" ? (
          <div></div>
        ) : (
          <div>
            <a
              href="#empty"
              onClick={(e) => {
                e.preventDefault();
                editOnclick(row);
              }}
            >
              <i className="fas fa-pencil-alt mr-3"></i>
            </a>
            <a
              href="#empty"
              onClick={(e) => {
                e.preventDefault();
                deleteOnClick(row);
              }}
            >
              <i className="fas fa-trash-alt"></i>
            </a>
          </div>
        );
      },
    },
  ]);
  const hideOneTrial = useCallback((item) => {
    return !(item.courseTypeId === 4 || item.courseTypeId === 5);
  });
  const newOnclick = (row) => {
    props.history.push(`${AppConfig.applicationPath}/courses/new`);
  };

  const editOnclick = (row) => {
    props.history.push(`${AppConfig.applicationPath}/courses/edit/${row.id}`);
  };

  const deleteData = async (id, courseType) => {
    console.log("deleteing id: ", id);
    const dataSubmit = {
      courseId: id,
    };
    const handler = new CourseService();
    try {
      if (courseType.toLowerCase() != "open") {
        await handler.delete(dataSubmit);
      } else {
        var courseSet = await handler.getCourseSet(id);
        await courseSet.map(async (c) => {
          await handler.delete({ courseId: c.ID });
        });
      }
    } catch (error) {
      console.log("error: ", error);
      Swal.fire("Deleted!", "Delete fail.", "error");
    }
    childRef.current.doAdvanceSearch({ searchBox });
    Swal.fire("Deleted!", "Your file has been deleted.", "success");
  };

  const deleteOnClick = async (row) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Are you sure you want to delete this information?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Please Wait !",
          html: "System processing", // add html attribute if you want or remove
          allowOutsideClick: false,
          showCancelButton: false,
          showConfirmButton: false,
          willOpen: () => {
            Swal.showLoading();
            try {
              deleteData(row.id, row.courseTypeName);
            } catch (error) {
              console.log("error: ", error);
            }
            childRef.current.setFilter({ searchBox });

            Swal.close();
          },
        });
      }
    });
  };

  return (
    <section className="content">
      <div className="container-fluid">
        <div className="row mt-2 mb-2">
          <div className="col-12">
            <div className="card">
              <div className="card-header">
                <h3 className="card-title">Courses</h3>
                <div className="card-tools">
                  <SearchBox
                    defaultValue={searchBox}
                    onChange={(e) => setSearchBox(e.target.value)}
                  />
                </div>
              </div>
              {/* /.card-header */}
              <div className="card-body">
                <Group>
                  <Switch
                    label="Show One, Trial course type"
                    value={filter.expand}
                    onChange={(e) =>
                      setFilter({ ...filter, expand: e.currentTarget.checked })
                    }
                  />
                  <Switch
                    label="Show expired"
                    value={filter.expired}
                    onChange={(e) =>
                      setFilter({ ...filter, expired: e.currentTarget.checked })
                    }
                  />
                </Group>

                <Button
                  leftIcon={<IconCirclePlus />}
                  onClick={newOnclick}
                  color="blue"
                >
                  New
                </Button>
                {/* <Tabs defaultValue="list">
                  <Tabs.List>
                    <Tabs.Tab value="list" icon={<IconList />}>
                      standard
                    </Tabs.Tab>
                    <Tabs.Tab value="table" icon={<IconCalendar />}>
                      timetable
                    </Tabs.Tab>
                  </Tabs.List>

                  <Tabs.Panel value="list" pt="xs">
                    <AdvancedPaginationTable
                      ref={childRef}
                      title="Courses"
                      columns={columns}
                      apiURL="\api\courses"
                      isSelectableRows={false}
                      noHeader={true}
                      postFilter={filter.expand ? null : hideOneTrial}
                    />
                  </Tabs.Panel>
                  <Tabs.Panel value="table" pt="xs">
                    <Timetable />
                  </Tabs.Panel>
                </Tabs> */}
                <AdvancedPaginationTable
                  ref={childRef}
                  title="Courses"
                  columns={columns}
                  apiURL="\api\courses"
                  isSelectableRows={false}
                  noHeader={true}
                  postFilter={filter.expand ? null : hideOneTrial}
                />
              </div>
              {/* /.card-body */}
            </div>
          </div>
          {/* /.col */}
        </div>
        {/* /.row */}
      </div>
      {/* /.container-fluid */}
    </section>
  );
}
