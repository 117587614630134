import env from "react-dotenv";

export const AppConfig = {
  // apiURL: "https://uat.api.harlemshake.verismartsoft.biz",
  // apiURL: "https://api.harlemshake.verismartsoft.biz",
  apiURL: process.env.REACT_APP_API_URL,
  // apiURL: env.API_BASE
  //   ? env.API_BASE
  //   : "https://api.harlemshake.verismartsoft.biz",
  storageNameToken:
    window.location.hostname + "_" + window.location.port + ".token",
  storageNameUserName:
    window.location.hostname + "_" + window.location.port + ".username",
  storageNameUserInfo:
    window.location.hostname + "_" + window.location.port + ".userinfo",
  applicationPath: "/page",
  loginPath: "/",
};
