import { Avatar, Card, Center, Group, Text, Tooltip } from "@mantine/core";
import { IconCircleCheck, IconClockHour4, IconTent } from "@tabler/icons";
import React from "react";
import { formatUTCTime } from "../utilities";

const ClassCard = ({ item }) => {
  return (
    <Card shadow={"sm"} withBorder m={2}>
      {/* {!item.valid && <Overlay zIndex={1} />} */}
      <Center>
        <Text fw={"bold"}>{item.Date}</Text>
      </Center>
      <Group>
        <Avatar radius="xl" src={item.instructorImagePath} />
        <div>
          <Group position="apart">
            <Text>{item.courseName || item.name}</Text>

            {item.valid && (
              <Tooltip
                label={
                  item.valid === true
                    ? "Generated as Today's class"
                    : "Not generated"
                }
                key={`${item.id}`}
              >
                <IconCircleCheck color="green" />
              </Tooltip>
            )}
          </Group>
          <Text>{item.instructorFirstName || item.instructorFirstname}</Text>
          <Group>
            <IconClockHour4 />
            <Text>
              {formatUTCTime(item.startCourse || item.startTime)} -{" "}
              {formatUTCTime(item.endCourse || item.endTime)}
            </Text>
          </Group>
        </div>
      </Group>
    </Card>
  );
};

export default ClassCard;
