import React, { useMemo, useRef, useEffect } from "react";
import Swal from "sweetalert2/dist/sweetalert2";
import AdvancedPaginationTable from "../../../@core/components/AdvancedPaginationTable";
import { AppConfig } from "../../../AppConfig";
import CourseService from "../../../services/master/CourseService";
import { Button } from "@mantine/core";
import { IconCirclePlus } from "@tabler/icons";
export default function CourseTypeList(props) {
  const childRef = useRef();

  const columns = useMemo(() => [
    {
      name: "Course Type Name",
      selector: "name",
      sortable: false,
    },
  ]);

  const newOnclick = (row) => {
    props.history.push(`${AppConfig.applicationPath}/courses/new`);
  };

  const deleteData = async (id) => {
    const dataSubmit = {
      courseId: id,
    };
    const handler = new CourseService();
    try {
      await handler.delete(dataSubmit);
      Swal.fire("Deleted!", "Your file has been deleted.", "success");
      childRef.current.doSearch();
    } catch (error) {
      console.log("error");
      Swal.fire("Deleted!", "Delete fail.", "error");
    }
  };
  const deleteOnClick = async (row) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Are you sure you want to delete this information?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Please Wait !",
          html: "System processing",
          allowOutsideClick: false,
          showCancelButton: false,
          showConfirmButton: false,
          willOpen: () => {
            Swal.showLoading();
            try {
              deleteData(row.id);
            } catch (error) {
              console.log("error");
            }

            Swal.close();
          },
        });
      }
    });
  };
  useEffect(() => {
    var isCancelled = false;
    if (!isCancelled) {
      childRef.current.setFilter({ searchBox: "" });
    }
    return () => {
      isCancelled = true;
    };
  }, []);
  return (
    <section className="content">
      <div className="container-fluid">
        <div className="row mt-2 mb-2">
          <div className="col-12">
            <div className="card">
              <div className="card-header">
                <h3 className="card-title">Course Type</h3>
              </div>
              {/* /.card-header */}
              <div className="card-body">
                <AdvancedPaginationTable
                  ref={childRef}
                  title="Courses"
                  columns={columns}
                  apiURL="\api\courseTypes"
                  isSelectableRows={false}
                  noHeader={true}
                />
              </div>
              {/* /.card-body */}
            </div>
          </div>
          {/* /.col */}
        </div>
        {/* /.row */}
      </div>
      {/* /.container-fluid */}
    </section>
  );
}
