import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useParams } from "react-router";
import Swal from "sweetalert2/dist/sweetalert2";
import { isNullEmpty, getTimezoneValue } from "../../../@core/utilities";
import { AppConfig } from "../../../AppConfig";
import { getUserId } from "../../../@core/services";
import CourseService from "../../../services/master/CourseService";
import TodayClassService from "../../../services/process/TodayClassService";
import UserService from "../../../services/master/UserService";
import Label from "../../../@core/components/Label";
import { format, utcToZonedTime } from "date-fns-tz";
import moment from "moment";
import { Container, Center } from "@mantine/core";
export default function TodayClassManage(props) {
  const { id } = useParams();
  const { register, handleSubmit, setValue } = useForm();
  const [instructorOptions, setInstructorOptions] = useState(null);
  const [method, setMethod] = useState("NEW");
  const [courseTypeOptions, setCourseTypeOptions] = useState(null);
  const [instructorSelected, setInstructorSelected] = useState("");
  const [instructorDefault, setInstructorDefault] = useState();
  const [courseTypeSelected, setCourseTypeSelected] = useState("");
  const [courseTypeDefault, setCourseTypeDefault] = useState();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [docDate, setDocDate] = useState(new Date());
  // Not null if Edit & courseType is "Open"
  const [oneClass, setOneClass] = useState(null);
  const [trialClass, setTrialClass] = useState(null);

  let messageError = "";
  useEffect(() => {
    async function fetchData() {
      const handler = new TodayClassService();
      const objinfo = await handler.get(id);
      console.log(objinfo);
      if (objinfo !== null) {
        setValue("courseName", objinfo.courseName);
        const timeZoneValue = getTimezoneValue();
        const docDateValue = utcToZonedTime(
          new Date(objinfo.date),
          timeZoneValue
        );

        setDocDate(docDateValue);
        //console.log(docDate);
        const startEndTime = objinfo.courseTime;
        const splitvalue = startEndTime.split(" ");
        //console.log(splitvalue);
        const startTime = splitvalue[0];
        const endTime = splitvalue[2];
        const docDateStartTime = `${format(
          docDate,
          "yyyy-MM-dd"
        )} ${startTime}`;
        //console.log(docDateStartTime);
        const docDateEndTime = `${format(docDate, "yyyy-MM-dd")} ${endTime}`;

        const docStartDateTimeValue = moment(
          docDateStartTime,
          "YYYY-MM-DD HH:mm"
        );
        //console.log(docStartDateTimeValue);
        setStartDate(docStartDateTimeValue.toDate());
        const docEndDateTimeValue = moment(docDateEndTime, "YYYY-MM-DD HH:mm");
        setEndDate(docEndDateTimeValue.toDate());

        //console.log(docStartDateTimeValue);
        //console.log(docEndDateTimeValue);
        await getInstructorOptions(objinfo.instructorId);
        await getCourseTypesOptions(objinfo.courseTypeId);
      } else {
        Swal.fire({
          title: "Error!",
          text: "Data not found",
          icon: "error",
          confirmButtonText: "Ok",
        });
        props.history.push(`${AppConfig.applicationPath}/todayclass`);
      }
    }
    if (!isNullEmpty(id)) {
      setMethod("EDIT");
      Swal.fire({
        title: "Please Wait !",
        html: "System processing",
        allowOutsideClick: false,
        showCancelButton: false,
        showConfirmButton: false,
        willOpen: () => {
          Swal.showLoading();
          fetchData();
          Swal.close();
        },
      });
    } else {
      getInstructorOptions();
      getCourseTypesOptions();
    }
  }, []);

  const backOnClick = () => {
    props.history.push(`${AppConfig.applicationPath}/todayclass`);
  };
  const isDisabledOption = (option) => {
    return (
      option.label.toLowerCase() === "trial" ||
      option.label.toLowerCase() === "one"
    );
  };
  const validateData = (data) => {
    /*
		console.log(courseTypeSelected);
		console.log(instructorSelected);
		console.log(docDate);
		*/
    messageError = "";
    //console.log(format(docDate, "yyyy-MM-dd"));
    let result = true;
    if (
      data.courseName === "" ||
      instructorSelected === "" ||
      instructorSelected === undefined ||
      (courseTypeSelected === "" && courseTypeSelected === undefined)
    ) {
      result = false;
      messageError = `please put data complete<br/>`;
    }

    if (startDate >= endDate) {
      result = false;
      messageError += `start time greater than or equal to end time`;
    }

    return result;
  };
  const onSubmit = async (data) => {
    if (!validateData(data)) {
      Swal.fire({
        title: "Error!",
        html: messageError,
        icon: "error",
        confirmButtonText: "Ok",
      });
      return false;
    }

    const timeValue = `${format(startDate, "HH:mm")} - ${format(
      endDate,
      "HH:mm"
    )}`;
    const handler = new TodayClassService();
    let result = null;
    if (method === "NEW") {
      //console.log("NEW");
      const dataSubmit = {
        date: format(docDate, "yyyy-MM-dd"),
        courseName: data.courseName,
        courseTime: timeValue,
        instructorId: instructorSelected,
        userId: getUserId(),
      };
      result = await handler.insert(dataSubmit);
    } else if (method === "EDIT") {
      const dataSubmit = {
        todayClassesId: id,
        date: format(docDate, "yyyy-MM-dd"),
        courseName: data.courseName,
        courseTime: timeValue,
        instructorId: instructorSelected,
        userId: getUserId(),
      };
      result = await handler.update(dataSubmit);
    }
    if (result !== null) {
      if (result.status === "00") {
        Swal.fire({
          icon: "info",
          title: "success",
          html: "Save data success.",
        }).then(() => {
          props.history.push(`${AppConfig.applicationPath}/todayclass`);
        });
      }
    }
  };

  const getInstructorInfo = async (id) => {
    const handler = new UserService();
    const response = await handler.get(id);
    console.log("instructorOptions: ", instructorOptions);
    var user = instructorOptions.find((i) => i.value === id);
    console.log("user: ", user);
    setInstructorDefault(user);
    setValue("instructorSelected", user.value);
    setInstructorSelected(user.value);
  };
  const getInstructorOptions = async (id = null) => {
    const handler = new UserService();
    const data = await handler.getInstructors();

    if (data !== undefined) {
      const options = data
        .map((d) => {
          if (d.roleName === "Instructor") {
            const val = {
              value: d.id,
              label: `${d.nickname}`,
            };
            return val;
          }
        })
        .filter((user) => user !== undefined);

      if (options === undefined) setInstructorOptions(null);
      else {
        setInstructorOptions(options);
        if (!!id) {
          var ins = options.find((i) => i.value === id);
          setInstructorDefault(ins);
          setValue("instructorSelected", ins.value);
          setInstructorSelected(ins.value);
        }
      }
    } else {
      setInstructorOptions(null);
    }
  };
  const getCourseTypesOptions = async (id = null) => {
    var handler = new CourseService();
    const data = await handler.getCourseTypes();
    if (data !== undefined) {
      const options = data.map((d) => ({
        value: d.id,
        label: d.name,
      }));
      setCourseTypeOptions(options);
      if (!!id) {
        var type = options.find((t) => t.value === id);
        setCourseTypeSelected(type.value);
        setCourseTypeDefault(type);
      }
    } else {
      setCourseTypeOptions(null);
    }
  };
  const handleInstructor_OnChange = (e) => {
    setInstructorSelected(e.value);
    setInstructorDefault({
      value: e.value,
      label: e.label,
    });
  };
  const handleCourseType_OnChange = (e) => {
    setCourseTypeSelected(e.value);
    setCourseTypeDefault({
      value: e.value,
      label: e.label,
    });
  };
  const fetchCourseSet = async (id) => {
    var handler = new CourseService();
    var response = await handler.getCourseSet(id);
    console.log("response: ", response);
    const one = courseTypeOptions.find((t) => t.label.toLowerCase() === "one");
    const trial = courseTypeOptions.find(
      (t) => t.label.toLowerCase() === "trial"
    );
    console.log("One: ", one);
    console.log("Trial: ", trial);

    setOneClass(response.find((c) => c.COURSETYPEID === one.value));
    setTrialClass(response.find((c) => c.COURSETYPEID === trial.value));
  };
  useEffect(() => {
    if (
      courseTypeOptions != null &&
      courseTypeDefault != null &&
      method === "EDIT" &&
      courseTypeDefault.label.toLowerCase() === "open"
    ) {
      console.log(`${method}: ${courseTypeDefault.label} => Fething courseSet`);
      fetchCourseSet(id);
    }
  }, [courseTypeDefault, method, courseTypeOptions]);
  return (
    <Container size="md">
      <Center style={{ paddingTop: 10 }}>
        <div
          className="row"
          style={
            {
              // marginBottom: 130,
            }
          }
        >
          <div className="col-12">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="card card-outline">
                <div className="card-header">
                  <h3 className="card-title">
                    <i className="fas fa-edit" />
                    Today Class
                  </h3>
                </div>
                <div className="card-body" style={{ paddingTop: 5 }}>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <Label text="Course Name" required />
                        <input
                          type="text"
                          name="courseName"
                          className="form-control"
                          ref={register()}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <Label text="Instructor Name" required />
                        <Select
                          name="instructorSelected"
                          value={instructorDefault}
                          options={instructorOptions}
                          onChange={handleInstructor_OnChange.bind(this)}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <Label text="Course Type" required />
                        <Select
                          name="courseTypeNameSelected"
                          value={courseTypeDefault}
                          options={courseTypeOptions}
                          onChange={handleCourseType_OnChange.bind(this)}
                          isOptionDisabled={(option) =>
                            isDisabledOption(option)
                          }
                          isDisabled={method === "EDIT"}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <Label text="Date" required />
                        <DatePicker
                          name="docDate"
                          selected={docDate}
                          minDate={new Date()}
                          dateFormat="dd/MM/yyyy"
                          className="form-control"
                          onChange={(date) => setDocDate(date)}
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <Label text="Start Times - End Times" required />
                      <div className="form-inline row ml-1">
                        <Label text="From" />

                        <div className="form-group col-sm-5">
                          <DatePicker
                            name="startDate"
                            selected={startDate}
                            dateFormat="HH:mm"
                            timeFormat="HH:mm"
                            className="form-control"
                            showTimeSelect
                            showTimeSelectOnly
                            onChange={(date) => setStartDate(date)}
                          />
                        </div>
                        <Label text="TO" />
                        <div className="form-group col-sm-5">
                          <DatePicker
                            name="endDate"
                            selected={endDate}
                            dateFormat="HH:mm"
                            timeFormat="HH:mm"
                            className="form-control"
                            showTimeSelect
                            showTimeSelectOnly
                            onChange={(date) => setEndDate(date)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-footer">
                  <button type="submit" className="btn btn-default mr-1">
                    Save
                  </button>
                  <button
                    type="button"
                    className="btn btn-default"
                    onClick={backOnClick}
                  >
                    Back
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Center>
    </Container>
  );
}
