import React, { useMemo, useRef, useEffect, useState } from "react";
import { useParams } from "react-router";
import { useForm } from "react-hook-form";
import { AppConfig } from "../../../AppConfig";
import Label from "../../../@core/components/Label";
import Swal from "sweetalert2/dist/sweetalert2";
import Select from "react-select";
import ReactPlayer from "react-player";
import InstructorProfileService from "../../../services/process/InstructorProfileService";
import UserService from "../../../services/master/UserService";
import { isNullEmpty } from "../../../@core/utilities";
import { getUserId } from "../../../@core/services";
import {
  Code,
  CopyButton,
  ActionIcon,
  Group,
  Center,
  Divider,
  Container,
  Accordion,
  List,
  Avatar,
  Text,
  Stack,
} from "@mantine/core";
import { IconCheck, IconCopy, IconMovie } from "@tabler/icons";
export default function InstructorProfileManage(props) {
  const { id } = useParams();
  const { register, handleSubmit, setValue } = useForm();
  const [method, setMethod] = useState("NEW");
  const [instructorOptions, setInstructorOptions] = useState(null);
  const [instructorSelected, setInstructorSelected] = useState(null);
  const [instructorDefault, setInstructorDefault] = useState();
  const [isHaveVideo, setIsHaveVideo] = useState(false);
  const [videoPath, setVideoPath] = useState("");
  const [instructorItems, setInstructorItems] = useState([]);
  const player = useRef();
  const customStyles = {
    container: () => ({
      // none of react-select's styles are passed to <Control />
      width: "100%",
    }),
  };
  let messageError = "";
  useEffect(() => {
    async function fetchData() {
      const handler = new InstructorProfileService();
      const objinfo = await handler.get(id);
      console.log(objinfo);
      if (objinfo !== null) {
        setValue("title", objinfo.title);
        setVideoPath(objinfo.videoPath);
        if (!isNullEmpty(objinfo.videoPath)) setIsHaveVideo(true);

        if (objinfo.instructorId1 > 0) {
          const newInstructor = {
            instructorId: objinfo.instructorId1,
            instructorNickName: objinfo.instructorNickname1,
            instructorFirstName: objinfo.instructorFirstname1,
            instructorLastName: objinfo.instructorLastname1,
          };
          instructorItems.push(newInstructor);
          //setInstructorItems([...instructorItems, newInstructor]);
        }
        if (objinfo.instructorId2 > 0) {
          const newInstructor = {
            instructorId: objinfo.instructorId2,
            instructorNickName: objinfo.instructorNickname2,
            instructorFirstName: objinfo.instructorFirstname2,
            instructorLastName: objinfo.instructorLastname2,
          };
          //setInstructorItems([...instructorItems, newInstructor]);
          instructorItems.push(newInstructor);
        }
        if (objinfo.instructorId3 > 0) {
          const newInstructor = {
            instructorId: objinfo.instructorId3,
            instructorNickName: objinfo.instructorNickname3,
            instructorFirstName: objinfo.instructorFirstname3,
            instructorLastName: objinfo.instructorLastname3,
          };
          //setInstructorItems([...instructorItems, newInstructor]);
          instructorItems.push(newInstructor);
        }
        if (objinfo.instructorId4 > 0) {
          const newInstructor = {
            instructorId: objinfo.instructorId4,
            instructorNickName: objinfo.instructorNickname4,
            instructorFirstName: objinfo.instructorFirstname4,
            instructorLastName: objinfo.instructorLastname4,
          };
          //setInstructorItems([...instructorItems, newInstructor]);
          instructorItems.push(newInstructor);
        }
        if (objinfo.instructorId5 > 0) {
          const newInstructor = {
            instructorId: objinfo.instructorId5,
            instructorNickName: objinfo.instructorNickname5,
            instructorFirstName: objinfo.instructorFirstname5,
            instructorLastName: objinfo.instructorLastname5,
          };
          //setInstructorItems([...instructorItems, newInstructor]);
          instructorItems.push(newInstructor);
        }
        getInstructorOptions();
      } else {
        Swal.fire({
          title: "Error!",
          text: "Data not found",
          icon: "error",
          confirmButtonText: "Ok",
        });
        props.history.push(`${AppConfig.applicationPath}/instructorprofile`);
      }
    }
    if (!isNullEmpty(id)) {
      setMethod("EDIT");
      Swal.fire({
        title: "Please Wait !",
        html: "System processing",
        allowOutsideClick: false,
        showCancelButton: false,
        showConfirmButton: false,
        willOpen: () => {
          Swal.showLoading();
          fetchData();
          Swal.close();
        },
      });
    } else {
      getInstructorOptions();
    }
  }, []);
  const handleInstructor_OnChange = (e) => {
    //console.log(e);
    setInstructorSelected({
      value: e.value,
      label: e.label,
      firstname: e.firstname,
      lastname: e.lastname,
    });
  };
  const handleChangeVideo1File = async (e) => {
    if (e.target.files[0]) {
      const file = e.target.files[0];
      const data = new FormData();
      data.append("upload1", file);
      await doUploadFileAsync(data, 1);
    }
  };
  async function doUploadFileAsync(data, seq) {
    const handler = new InstructorProfileService();
    const result = await handler.uploadVideo(data);
    if (result !== null) {
      if (result.status === "00") {
        Swal.fire({
          icon: "info",
          title: "success",
          html: `Upload video file success.`,
        }).then(() => {
          setVideoPath(result.data);
          //setVideoPath('https://api.live.harlemshake.verismartsoft.biz/vdo/02/02.m3u8');
          setIsHaveVideo(true);
        });
      }
    }
  }
  const getInstructorOptions = async (id = null) => {
    const handler = new UserService();
    const data = await handler.getInstructors();
    //console.log(data);
    if (data !== undefined) {
      const options = data
        .map((d) => {
          if (d.roleName === "Instructor") {
            const val = {
              value: d.id,
              label: `${d.nickname}`,
              firstname: `${d.firstName}`,
              lastname: `${d.lastName}`,
            };
            return val;
          }
        })
        .filter((user) => user !== undefined);

      if (options === undefined) setInstructorOptions(null);
      else {
        setInstructorOptions(options);
        if (!!id) {
          var ins = options.find((i) => i.value === id);
          setInstructorDefault(ins);
          setValue("instructorSelected", ins.value);
          setInstructorSelected(ins.value);
        }
      }
    } else {
      setInstructorOptions(null);
    }
  };
  const backOnClick = () => {
    props.history.push(`${AppConfig.applicationPath}/instructorprofile`);
  };
  const addInstructor_OnClick = () => {
    //console.log(instructorSelected);
    if (instructorSelected !== null) {
      if (instructorItems.length > 4) {
        Swal.fire({
          title: "Error!",
          html: "Can't add more than 5 items.",
          icon: "error",
          confirmButtonText: "Ok",
        });
        return;
      }

      const objfound = instructorItems.filter((element) => {
        return element.instructorId === instructorSelected.value;
      });
      //console.log(objfound);
      if (objfound.length === 0) {
        const newInstructor = {
          instructorId: instructorSelected.value,
          instructorNickName: instructorSelected.label,
          instructorFirstName: instructorSelected.firstname,
          instructorLastName: instructorSelected.lastname,
        };

        setInstructorItems([...instructorItems, newInstructor]);
      } else {
        Swal.fire({
          title: "Error!",
          html: "Can't add duplicates",
          icon: "error",
          confirmButtonText: "Ok",
        });
      }
    } else {
      Swal.fire({
        title: "Error!",
        html: "Please select instructor",
        icon: "error",
        confirmButtonText: "Ok",
      });
    }
  };
  const handleRemoveRow = (value) => () => {
    let items = instructorItems.filter(
      (row) => row.instructorId != value.instructorId
    );
    setInstructorItems(items);
  };
  const validateData = (data) => {
    messageError = "";
    let result = true;
    if (data.title === "") {
      result = false;
      messageError = `Incomplete form<br/>`;
    }
    return result;
  };
  const onSubmit = async (data) => {
    if (!validateData(data)) {
      Swal.fire({
        title: "Error!",
        html: messageError,
        icon: "error",
        confirmButtonText: "Ok",
      });
      return false;
    }
    console.log("data: ", data);
    console.log("items: ", instructorItems.length);
    if (instructorItems.length === 0) {
      console.log("instructor empty");
      Swal.fire({
        title: "Warning!",
        html: "Instructor list is empty<br/>",
        icon: "warning",
        confirmButtonText: "Ok",
      });
    }
    const handler = new InstructorProfileService();
    let result = null;

    let instructorId1 = "";
    let instructorId2 = "";
    let instructorId3 = "";
    let instructorId4 = "";
    let instructorId5 = "";
    if (instructorItems !== null) {
      console.log("items is not null");
      instructorItems.forEach((element, index) => {
        if (index === 0) instructorId1 = element.instructorId;
        else if (index === 1) instructorId2 = element.instructorId;
        else if (index === 2) instructorId3 = element.instructorId;
        else if (index === 3) instructorId4 = element.instructorId;
        else if (index === 4) instructorId5 = element.instructorId;
      });
    }
    if (method === "NEW") {
      const dataSubmit = {
        title: data.title,
        videoPath: videoPath,
        instructorId1: instructorId1,
        instructorId2: instructorId2,
        instructorId3: instructorId3,
        instructorId4: instructorId4,
        instructorId5: instructorId5,
        userId: getUserId(),
      };
      result = await handler.insert(dataSubmit);
    } else if (method === "EDIT") {
      const dataSubmit = {
        id: id,
        title: data.title,
        videoPath: videoPath,
        instructorId1: instructorId1,
        instructorId2: instructorId2,
        instructorId3: instructorId3,
        instructorId4: instructorId4,
        instructorId5: instructorId5,
        userId: getUserId(),
      };
      result = await handler.update(dataSubmit);
    }
    if (result !== null) {
      if (result.status === "00") {
        var isInstructorList = instructorItems.length !== 0;
        Swal.fire({
          icon: isInstructorList ? "success" : "warning",
          title: isInstructorList ? "success" : "success with warning",
          html: `Save data success. <span style={{backgroundColor:'yellow'}} >${
            isInstructorList ? "" : "Missing instructor list"
          }</>`,
          confirmButtonText: "Ok",
        }).then(() => {
          props.history.push(`${AppConfig.applicationPath}/instructorprofile`);
        });
      }
    }
  };
  return (
    <Container size="md">
      <Center style={{ paddingTop: 10 }}>
        <div className="row" style={{ marginBottom: 130 }}>
          <div className="col-12">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="card card-outline">
                <div className="card-header">
                  <h3 className="card-title">
                    <i className="fas fa-edit" />
                    Instructor Profile
                  </h3>
                </div>
                <div
                  className="card-body"
                  style={{ paddingTop: 5, marginTop: 10 }}
                >
                  <div className="row">
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-12 col-sm-12">
                          <Label text="Title" required />
                          <input
                            type="text"
                            name="title"
                            className="form-control"
                            ref={register()}
                          />
                        </div>
                        <div className="col-md-12 col-sm-12  pt-1">
                          {/* {isHaveVideo === true ? (
                        <div>{videoPath}</div>
                      ) : (
                        <label>&nbsp;</label>
                      )} */}

                          <div className="custom-file mt-2">
                            <input
                              type="file"
                              className="form-control custom-file-input"
                              accept="video/mp4,video/x-m4v,video/*"
                              onChange={handleChangeVideo1File}
                            />
                            <label
                              className="custom-file-label"
                              htmlFor="customInput"
                            >
                              Choose file...
                            </label>
                          </div>
                        </div>

                        <div className="col-sm-12">
                          <Divider my="sm" />

                          <Accordion
                            variant="filled"
                            mt={10}
                            style={{ backgroundColor: "#F8F9FA" }}
                          >
                            <Accordion.Item value="VideoGuide">
                              <Accordion.Control>
                                <Group noWrap>
                                  <IconMovie size={30} />
                                  <Text
                                  // style={{ fontSize: 15, fontWeight: "bold" }}
                                  >
                                    Video Specs
                                  </Text>
                                  <Text size="sm" color="dimmed">
                                    Tutorial for uploading videos for new user
                                  </Text>
                                </Group>
                              </Accordion.Control>
                              <Accordion.Panel>
                                Uses "
                                <a href="https://handbrake.fr/">Handbrake</a>"
                                program to reformat video into these settings...
                                <List>
                                  <List.Item>
                                    Limited Video Resolution : Up to 1080p
                                  </List.Item>
                                  <List.Item>
                                    Supported Format : MOV, mp4
                                  </List.Item>
                                  <List.Item>Supported Codec : H264</List.Item>
                                </List>
                              </Accordion.Panel>
                            </Accordion.Item>
                          </Accordion>
                          <Divider my="sm" />
                        </div>

                        <Center style={{ margin: 8, width: "100%" }}>
                          <Stack>
                            <ReactPlayer
                              controls={true}
                              // width="50%"
                              // height="50%"
                              url={videoPath}
                            />
                            {isHaveVideo && (
                              <Code>
                                <Group position="apart">
                                  {videoPath}
                                  <CopyButton size="xs" value={videoPath}>
                                    {({ copied, copy }) => (
                                      <ActionIcon
                                        color={copied ? "teal" : "gray"}
                                        onClick={copy}
                                      >
                                        {copied ? (
                                          <IconCheck size={16} />
                                        ) : (
                                          <IconCopy size={16} />
                                        )}
                                      </ActionIcon>
                                    )}
                                  </CopyButton>
                                </Group>
                              </Code>
                            )}
                          </Stack>
                        </Center>

                        <div className="col-md-12">
                          <Divider my="sm" />

                          <Label text="Instructor" />
                          <div className="form-inline row">
                            <div className="form-group col-sm-5">
                              <Select
                                name="instructorSelected"
                                styles={customStyles}
                                value={instructorDefault}
                                options={instructorOptions}
                                onChange={handleInstructor_OnChange.bind(this)}
                              />
                            </div>
                            <div className="form-group col-sm-5">
                              <label>&nbsp;</label>
                              <button
                                type="button"
                                className="btn btn-default"
                                onClick={addInstructor_OnClick}
                              >
                                Add
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {instructorItems.length !== 0 && (
                    <div className="row mt-2">
                      <div className="col-md-12">
                        <table className="table table-hover">
                          <thead>
                            <tr>
                              <th scope="col">First name</th>
                              <th scope="col">Last name</th>
                              <th scope="col">Nick name</th>
                              <th scope="col"></th>
                            </tr>
                          </thead>
                          <tbody>
                            {instructorItems.map((item, index) => (
                              <tr key={index}>
                                <td>{item.instructorFirstName}</td>
                                <td>{item.instructorLastName}</td>
                                <td>{item.instructorNickName}</td>
                                <td>
                                  <button
                                    type="button"
                                    className="btn btn-default"
                                    onClick={handleRemoveRow(item)}
                                  >
                                    Delete
                                  </button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )}
                </div>
                <div className="card-footer">
                  <button type="submit" className="btn btn-default mr-1">
                    Save
                  </button>
                  <button
                    type="button"
                    className="btn btn-default"
                    onClick={backOnClick}
                  >
                    Back
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Center>
    </Container>
  );
}
