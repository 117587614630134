import axios from "axios";
import { getToken } from "../../@core/services";
import { AppConfig } from "../../AppConfig";

class InstructorProfileService {
  constructor() {}
  async insert(data) {
    const urlAPI = `${AppConfig.apiURL}/api/instructorProfiles/insert`;
    const config = {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    };
    const response = await axios.post(urlAPI, data, config);
    console.log(response);
    if (response.status === 200) {
      return { status: "00" };
    }
  }
  async delete(id) {
    const urlAPI = `${AppConfig.apiURL}/api/instructorProfiles/delete`;
    const config = {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    };
    const dataSubmit = {
      // todayClassesId: id
      id,
    };
    let response = null;
    try {
      response = await axios.post(urlAPI, dataSubmit, config);
      console.log(response);
      if (response.status === 200) {
        return { status: "00" };
      }
    } catch (error) {
      throw error;
    }
  }
  async get(id) {
    const urlAPI = `${AppConfig.apiURL}/api/instructorProfiles/${id}`;
    const config = {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    };
    const response = await axios.get(urlAPI, config);
    //console.log(response);
    if (response.status === 200) {
      return response.data;
    } else return null;
  }
  async update(data) {
    const urlAPI = `${AppConfig.apiURL}/api/instructorProfiles/update`;
    const config = {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    };
    const response = await axios.post(urlAPI, data, config);
    console.log(response);
    if (response.status === 200) {
      return { status: "00" };
    }
  }
  async uploadVideo(data) {
    const urlAPI = `${AppConfig.apiURL}/api/instructorProfiles/uploadVdo`;
    const config = {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    };
    const response = await axios.post(urlAPI, data, config);
    //console.log(response.data);
    if (response.status === 200) {
      return { status: "00", data: response.data.data };
    }
  }
}

export default InstructorProfileService;
