import axios from "axios";
import { getToken } from "../../@core/services";
import { AppConfig } from "../../AppConfig";

class CourseTakingService {
  constructor() {}

  async get(id) {
    const urlAPI = `${AppConfig.apiURL}/api/courseTaking/${id}`;
    const config = {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    };
    const response = await axios.get(urlAPI, config);
    console.log(response);
    if (response.status === 200) {
      return response.data;
    } else return null;
  }
  async delete(data) {
    const urlAPI = `${AppConfig.apiURL}/api/courseTaking/delete`;
    const config = {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    };
    let response = null;
    try {
      response = await axios.post(urlAPI, data, config);
      if (response.status === 200) {
        return { status: "00" };
      }
    } catch (error) {
      throw error;
    }
  }
}

export default CourseTakingService;
