import React, { useMemo, useRef, useEffect, useState } from "react";
import Swal from "sweetalert2/dist/sweetalert2";
import AdvancedPaginationTable from "../../../@core/components/AdvancedPaginationTable";
import { castString } from "../../../@core/utilities/index";
import { AppConfig } from "../../../AppConfig";
import InstructorProfileService from "../../../services/process/InstructorProfileService";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  Button,
  CopyButton,
  Notification,
  ActionIcon,
  TextInput,
} from "@mantine/core";
import {
  IconCirclePlus,
  IconCopy,
  IconCopyOff,
  IconCheck,
} from "@tabler/icons";
import { useDebouncedState } from "@mantine/hooks";
import SearchBox from "../../../@core/components/SearchBox";
export default function InstructorList(props) {
  const [searchBox, setSearchBox] = useDebouncedState("", 200);
  const childRef = useRef();
  const [url, setUrl] = useState(null);
  const columns = useMemo(() => [
    {
      name: "Video Link",

      cell: (row) => (
        <CopyButton size="xs" value={row.videoPath}>
          {({ copied, copy }) => (
            <Button
              variant="outline"
              size="xs"
              color={copied ? "teal" : "gray"}
              leftIcon={
                copied ? <IconCheck size={16} /> : <IconCopy size={16} />
              }
              onClick={copy}
            >
              link
            </Button>
          )}
        </CopyButton>
      ),
      sortable: false,
    },
    {
      name: "Title",
      selector: "title",
      sortable: false,
    },
    {
      name: "Instructor #1",
      sortable: false,
      cell: (row) => <div>{castString(row.instructorNickname1)}</div>,
    },
    {
      name: "Instructor #2",
      sortable: false,
      cell: (row) => <div>{castString(row.instructorNickname2)}</div>,
    },
    {
      name: "Instructor #3",
      sortable: false,
      cell: (row) => <div>{castString(row.instructorNickname3)}</div>,
    },
    {
      name: "Instructor #4",
      sortable: false,
      cell: (row) => <div>{castString(row.instructorNickname4)}</div>,
    },
    {
      name: "Instructor #5",
      sortable: false,
      cell: (row) => <div>{castString(row.instructorNickname5)}</div>,
    },
    {
      cell: (row) => {
        return (
          <div>
            <a
              href="#empty"
              onClick={(e) => {
                e.preventDefault();
                editOnclick(row);
              }}
            >
              <i className="fas fa-pencil-alt mr-3"></i>
            </a>
            <a
              href="#empty"
              onClick={(e) => {
                e.preventDefault();
                deleteOnClick(row);
              }}
            >
              <i className="fas fa-trash-alt"></i>
            </a>
          </div>
        );
      },
    },
  ]);
  const fnCallAPIDeleteData = async (id) => {
    const handler = new InstructorProfileService();
    try {
      const result = await handler.delete(id);
      if (result !== null) {
        if (result.status === `00`) {
          Swal.close();
          Swal.fire({
            icon: `info`,
            title: `Deleted`,
            html: `Your file has been deleted.`,
          }).then(() => {
            // triggering re-fetch data
            childRef.current.setFilter({ searchBox });
          });
        }
      }
    } catch (error) {
      console.log("error");
      Swal.fire("Deleted!", "Delete fail.", "error");
    }
  };
  const newOnclick = () => {
    props.history.push(`${AppConfig.applicationPath}/instructorprofile/new`);
  };
  const editOnclick = (row) => {
    props.history.push(
      `${AppConfig.applicationPath}/instructorprofile/edit/${row.id}`
    );
  };
  const deleteOnClick = (row) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Are you sure you want to delete this information?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Please Wait !",
          html: "System processing", // add html attribute if you want or remove
          allowOutsideClick: false,
          showCancelButton: false,
          showConfirmButton: false,
          willOpen: async () => {
            Swal.showLoading();
            try {
              await fnCallAPIDeleteData(row.id);
            } catch (error) {
              console.log("error");
            }
          },
        });
      }
    });
  };

  useEffect(() => {
    var isCancelled = false;
    if (!isCancelled) {
      childRef.current.setFilter({ searchBox });
    }
    return () => {
      isCancelled = true;
    };
  }, [searchBox]);
  return (
    <section className="content">
      <div className="container-fluid">
        <div className="row mt-2 mb-2">
          <div className="col-12">
            <div className="card">
              <div className="card-header">
                <h3 className="card-title">Instructor Profile</h3>
                <div className="card-tools">
                  <SearchBox
                    defaultValue={searchBox}
                    onChange={(e) => setSearchBox(e.target.value)}
                  />
                </div>
              </div>
              {/* /.card-header */}
              <div className="card-body">
                <Button
                  leftIcon={<IconCirclePlus />}
                  onClick={newOnclick}
                  color="blue"
                >
                  New
                </Button>
                <AdvancedPaginationTable
                  ref={childRef}
                  title="Today Class"
                  columns={columns}
                  apiURL="/api/instructorProfiles"
                  isSelectableRows={false}
                  noHeader={true}
                  showExport={false}
                />
              </div>
              {/* /.card-body */}
            </div>
          </div>
          {/* /.col */}
        </div>
        {/* /.row */}
      </div>
      {/* /.container-fluid */}
    </section>
  );
}
