import React, { useCallback, useEffect, useMemo, useState } from "react";
//import React, { useState, useEffect, useMemo } from "react";
//import { useEffect, useMemo } from "react";
import Select from "react-select";
import AsyncSelect from "react-select/async";

import { isNullEmpty } from "../../../@core/utilities";
import { getUserId } from "../../../@core/services";
import { getToken } from "../../../@core/services";
import { format } from "date-fns";
import { AppConfig } from "../../../AppConfig";
import axios from "axios";
import { useParams } from "react-router";
import Swal from "sweetalert2/dist/sweetalert2";
import { useForm } from "react-hook-form";
import CourseService from "../../../services/master/CourseService";
import UserService from "../../../services/master/UserService";
import PaymentService from "../../../services/process/PaymentService";
import Label from "../../../@core/components/Label";
import DatePicker from "react-datepicker";
import { Container, Center } from "@mantine/core";
export default function PaymentManage(props) {
  const { id } = useParams();
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    clearErrors,
    watch,
    formState: { errors },
  } = useForm();
  const [method, setMethod] = useState("NEW");

  const [courseDefault, setCourseDefault] = useState(null);
  const [coursesOptions, setCoursesOptions] = useState(null);
  const [courseTypeSelected, setCourseTypeSelected] = useState(1);
  const [totalDefault, setTotalDefault] = useState(0);
  const MODE = useMemo(() => ({ default: 1, firstEnroll: 2 }), []);
  const [totalMode, setTotalMode] = useState(MODE.default);
  const [priceDefault, setPriceDefault] = useState(0);
  const [qtyDefault, setQtyDefault] = useState(0);
  const [additionalFee, setFee] = useState(0);
  const [discountDefault, setDiscountDefault] = useState(0);
  const [discountMsg, setDiscountMsg] = useState("");
  const [isPercentageDiscount, setIsPercentageDiscount] = useState(false);
  const [paymentTypeDefault, setPaymentTypeDefault] = useState(1);
  const paymentTypeOptions = useMemo(() => [
    { value: 1, label: "credit card (+ 3%)" },
    { value: 2, label: "cash" },
    { value: 3, label: "transfer" },
  ]);
  const [courseTypeDefault, setCourseTypeDefault] = useState(1);

  const [courseTypeOptions, setCourseTypeOptions] = useState(null);
  const [enableCourse, setEnableCourse] = useState(false);
  const [codeDefault, setCodeDefault] = useState("");
  const isEnableCourse = (label) => {
    if (label === null || label === undefined) return false;
    var lowerCase = label.toLowerCase();
    return lowerCase != "open" && lowerCase != "trial" && lowerCase != "one";
  };
  const [expDate, setExpDate] = useState(new Date());

  const toggleTotalMode = () => {
    if (totalMode === MODE.default) setTotalMode(MODE.firstEnroll);
    else setTotalMode(MODE.default);
  };
  useEffect(() => {
    async function fetchData() {
      await getCoursesOption();

      const handler = new PaymentService();
      const objinfo = await handler.get(id);
      if (objinfo !== null) {
        setValue("codeSelected", [
          {
            value: objinfo.studentId,
            label: `${objinfo.studentCode}: ${objinfo.firstName}`,
          },
        ]);
        setCodeDefault([
          {
            value: objinfo.studentId,
            label: `${objinfo.studentCode}: ${objinfo.firstName}`,
          },
        ]);
        await getCourseTypesOptions(objinfo.courseTypeId);
        if (
          // if NOT OPEN COURSE
          objinfo.fixedCourseId != null &&
          objinfo.fixedCourseId != "" &&
          objinfo.fixedCourseId != undefined
        ) {
          var course = await getCourse(objinfo.fixedCourseId);
          if (course != null) {
            setCourseDefault(course);
            setValue("courseSelected", course.value);
          }
          const currentDate = new Date();
          setExpDate(currentDate.setFullYear(currentDate.getFullYear() + 1));
        }

        let paymentType = paymentTypeOptions.filter(
          (e) => e.value === objinfo.paymentChannelId
        )[0];
        setPaymentTypeDefault(paymentType);
        setValue("paymentTypeSelected", paymentType.value);
        setValue("qty", parseInt(objinfo.qty));
        setValue("price", parseFloat(objinfo.price));
        setValue("discount", objinfo.discount);
        setValue("received", objinfo.isReceived);
        setValue("closed", objinfo.isClosedPayment);
        setValue("remark", objinfo.remark);
        setExpDate(new Date(objinfo.expired));
      }
    }
    if (!isNullEmpty(id)) {
      setMethod("EDIT");
      Swal.fire({
        title: "Please Wait !",
        html: "System processing", // add html attribute if you want or remove
        allowOutsideClick: false,
        showCancelButton: false,
        showConfirmButton: false,
        willOpen: () => {
          Swal.showLoading();
          fetchData();
          Swal.close();
        },
      });
    } else {
      getCourseTypesOptions();
      getCoursesOption();
      const currentDate = new Date();
      // setExpDate(currentDate.setFullYear(currentDate.getFullYear() + 1));
      setExpDate(currentDate.setMonth(currentDate.getMonth() + 6));
    }
    console.log(id);
  }, []);
  const onError = (errors, e) => console.log(errors, e);

  const validateData = (data) => {
    let result = true;
    return result;
  };
  const getCourseTypesOptions = async (id) => {
    const config = {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    };
    const urlAPI = `${AppConfig.apiURL}/api/courseTypes?page=${1}&size=${1000}`;

    const response = await axios.post(urlAPI, null, config);
    const data = response.data.data.rows;

    if (data !== undefined) {
      const options = data.map((d) => ({
        value: d.id,
        label: d.name,
      }));
      setCourseTypeOptions(options);
      if (id != null) {
        var fitlered = options.filter((o) => o.value === id);
        setValue("courseTypeSelected", id);
        setCourseTypeSelected(id);
        setCourseTypeDefault(fitlered[0]);
      }
    } else {
      setCourseTypeOptions(null);
    }
  };
  const getCourse = async (id) => {
    const handler = new CourseService();
    var response = await handler.get(id);

    return {
      value: response.id,
      label: response.courseName + " by " + response.instructorFirstName,
      instructor: response.instructorId,
    };
  };
  const getCoursesOption = async (value = "") => {
    const config = {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    };
    var page = 1;
    var size = 1000;
    const urlAPI = `${AppConfig.apiURL}/api/courses?page=${page}&size=${size}`;
    const data = await axios.post(
      urlAPI,
      { searchBox: value, expired: 0 },
      config
    );
    if (data !== undefined) {
      const options = data.data.data.rows.map((d) => ({
        value: d.id,
        label: d.courseName + " by " + d.instructorFirstName,
        instructor: d.instructorId,
      }));
      setCoursesOptions(options);
      return options;
    } else {
      setCoursesOptions([]);
      return null;
    }
  };
  const getCodesOption = async (studentCode) => {
    const handler = new UserService();
    const data = await handler.searchByStudent(studentCode);
    if (data !== undefined) {
      var options = data.map((d) => {
        var name = "-";
        if (d.lastName != "" || d.lastName != null) name = d.lastName;
        if (d.firstName != "" || d.firstName != null) name = d.firstName;
        if (d.nickname != "" || d.nickname != null) name = d.nickname;

        if (d.firstName != "" && d.firstName != null) {
          name = `${name} (${d.firstName})`;
        }
        return {
          value: d.id,
          label: `${d.code}: ${name}`,
        };
      });
      return options;
    } else {
      return [];
    }
  };
  const loadCourseOption = (inputValue, callback) => {
    var result = new Promise((resolve) => {
      resolve(getCoursesOption(inputValue));
    });
    result.then((res) => {
      callback(res);
    });
  };
  const loadCodeOption = (inputValue, callback) => {
    var result = new Promise((resolve) => {
      resolve(getCodesOption(inputValue));
    });
    result.then((res) => {
      callback(res);
    });
  };
  const handleCode_OnChange = async (newValue) => {
    // setValue("codeSelected", e.value);
    // setCodeDefault({ value: e.value, label: e.label });
    // const inputValue = newValue.replace(/W/g, "");
    // this.setState({ inputValue });
    // setCodeDefault(inputValue);
    setCodeDefault(newValue);
    setValue("codeSelected", newValue);
    return newValue;
  };
  const handlePaymentType_OnChange = (e) => {
    setValue("paymentTypeSelected", e.value);
    setPaymentTypeDefault({ value: e.value, label: e.label });
  };
  const handleCourse_OnChange = (e) => {
    setValue("courseSelected", e.value);
    setCourseDefault({
      value: e.value,
      label: e.label,
      instructor: e.instructor,
    });
  };
  const handlePrice_OnChange = (e) => {
    setValue("price", e.target.value);
    setPriceDefault(e.target.value);
  };
  const handleQty_OnChange = (e) => {
    setValue("qty", e.target.value);
    setQtyDefault(e.target.value);
  };
  const handleFee_OnChange = (e) => {
    // if (e.target.value <= 0) setFee(0);
    setFee(parseFloat(e.target.value));
  };
  const handleDiscount_OnChange = (e) => {
    setDiscountDefault(e.target.value);
    setValue("discount", e.target.value);
  };

  const handleCourseType_OnChange = (e) => {
    console.log("test");
    setValue("courseTypeSelected", e.value);
    setCourseTypeSelected(e.value);
    setCourseTypeDefault({
      value: e.value,
      label: e.label,
    });
    // if (e.label.toLowerCase() != "open") setEnableCourse(false);
    setEnableCourse(isEnableCourse(e.label));
  };
  const onSubmit = async (data) => {
    if (!validateData(data)) {
      Swal.fire({
        title: "Error!",
        html: messageError,
        icon: "error",
        confirmButtonText: "Ok",
      });
      return false;
    }
    // console.log("Submit data: ", data);
    const handler = new PaymentService();
    let result = null;
    var openCourse = courseTypeOptions.find(
      (t) => t.label.toLowerCase() === "open"
    );
    // var isFixedCourse = courseTypeSelected != openCourse.value;
    var isFixedCourse = isEnableCourse(courseTypeDefault.label);
    console.log("isFixedCourse:", isFixedCourse);
    var dataFormat = data.codeSelected.map((studentId) => ({
      paymentId: id,
      studentId: studentId.value,
      price: data.price,
      qty: data.qty,
      discount: data.discount,
      total: data.total,
      paymentChannelId: data.paymentTypeSelected,
      courseTypeId: courseTypeDefault.value,
      isFixed: isFixedCourse,
      fixedCourseId: isFixedCourse ? courseDefault.value : null,
      fixedCourseInstructorId: isFixedCourse ? courseDefault.instructor : null,
      isClosedPayment: data.closed,
      isReceived: data.received,
      remark: data.remark,
      userId: getUserId(),
      expDate: isFixedCourse ? null : format(expDate, "yyyy-MM-dd"),
    }));
    console.log("dataFormat: ", dataFormat);
    if (method === "NEW") {
      console.log("NEW");
      var submitResults = dataFormat.map((toSubmit) => {
        var response = handler.insert(toSubmit);
        return response;
      });

      Promise.all(submitResults).then((resolved) => {
        var ok = 0;
        var err = [];
        resolved.map((res) => {
          console.log("resolved: ", res);
          if (res === null) {
            err.push(null);
          }
          if (res.status != "00") {
            err.push(res);
          } else {
            ok = ok + 1;
            err.push(true);
          }
        });
        console.log("OK: ", ok);
        if (ok === submitResults.length) {
          Swal.fire({
            icon: "info",
            title: "success",
            html: "Save data success.",
          }).then(() => {
            props.history.push(`${AppConfig.applicationPath}/payment`);
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Error",
            html: "Unsuccessful submission!",
          }).then(() => {
            console.log("errors: ", err);
          });
        }
      });
    } else if (method === "EDIT") {
      result = await handler.update(dataFormat[0]);

      if (result != null) {
        if (result.status === "00") {
          Swal.fire({
            icon: "info",
            title: "success",
            html: "Save data success.",
          }).then(() => {
            props.history.push(`${AppConfig.applicationPath}/payment`);
          });
        }
      }
    }
  };
  const backOnClick = () => {
    props.history.push(`${AppConfig.applicationPath}/payment`);
  };
  var messageError = "";

  const addDiscount = (priceTotal, discountVal) => {
    if (discountVal === null || discountVal === undefined || discountVal === "")
      return priceTotal;
    if (!isNaN(discountVal)) {
      if (parseFloat(discountVal) < 0) {
        setDiscountMsg(
          "Wrong input format: only positive normal numbers are accepted"
        );
        return priceTotal;
      }
      return priceTotal - parseFloat(discountVal);
    } else {
      var newVal = discountVal.replace("%", "");
      if (!isNaN(newVal)) {
        if (parseFloat(newVal) < 0) {
          setDiscountMsg(
            "Wrong input format: only positive normal numbers are accepted"
          );
          return priceTotal;
        }
        return priceTotal - (priceTotal * parseFloat(newVal)) / 100;
      } else {
        setDiscountMsg(
          "Wrong input format: only Percetage and positive normal numbers are accepted"
        );
        return priceTotal;
      }
    }
  };
  const calculateTotal = (price, qty, discount, fee = 0) => {
    setDiscountMsg("");
    // console.log("price: ", price);
    // console.log("qty: ", qty);
    // console.log("discount: ", discount);
    // console.log("calculating with fee: ", fee);
    if (price === "" || price === undefined || qty === "" || qty === undefined)
      return;
    // var priceTotal = parseFloat(price);
    var total = addDiscount(parseFloat(price), discount);
    var creditValue =
      paymentTypeDefault.label === "credit card (+ 3%)" ? total * 0.3 : 0;

    var finalValue = total + creditValue + parseFloat(fee);
    setTotalDefault(finalValue);
    setValue("total", finalValue);
  };
  // const calculateTotal = (price, qty, discount, fee = 0) => {
  //   setDiscountMsg("");
  //   if (price === "" || price === undefined || qty === "" || qty === undefined)
  //     return;
  //   var priceTotal = parseFloat(price);
  //   var total = addDiscount(priceTotal, discount);
  //   if (paymentTypeDefault.label === "credit card (+ 3%)") {
  //     var result = total + total * 0.03;
  //     // setTotalDefault(result);
  //     // setValue("total", result);
  //     setTotalDefault(result + fee);
  //     setValue("total", result + fee);
  //   } else {
  //     // setTotalDefault(total + additionalFee);
  //     // setValue("total", total + additionalFee);
  //     setTotalDefault(result + fee);
  //     setValue("total", result + fee);
  //   }
  // };
  let watchFields = watch(["price", "qty", "discount", "fee"]);
  useEffect(() => {
    // if (totalMode === MODE.default)
    calculateTotal(
      watchFields.price,
      watchFields.qty,
      watchFields.discount,
      watchFields.fee
    );

    return () => {};
  }, [watchFields]);

  useEffect(() => {
    setEnableCourse(isEnableCourse(courseTypeDefault.label));
  }, [courseTypeDefault]);
  const addFee = (total, fee) => {
    if (total + fee < 0) {
      return 0;
    } else {
      return total + fee;
    }
  };
  const getFee = async () => {
    const { value: fee } = await Swal.fire({
      title: "Additional Fee",
      input: "number",
      inputPlaceholder: "enrollment fee",
    });
    if (fee === null || fee === "" || fee === undefined) {
      setTotalMode(MODE.default);
      setFee(0);
    } else {
      setFee(parseFloat(fee));

      // if (totalDefault + parseFloat(fee) < 0) {
      //   setTotalDefault(0);
      //   setValue("total", 0);
      // } else {
      //   setTotalDefault(totalDefault + parseFloat(fee));
      //   setValue("total", totalDefault + pars eFloat(fee));
      // }
    }
  };
  // useEffect(() => {
  //   if (totalMode === MODE.firstEnroll) {
  //     getFee();
  //   }
  //   {
  //     setFee(0);
  //   }

  //   return () => {};
  // }, [totalMode]);
  // useEffect(() => {
  //   calculateTotal();
  //   return () => {};
  // }, [additionalFee]);
  return (
    <Container size="lg">
      <Center style={{ paddingTop: 10 }}>
        <div className="row" style={{ marginBottom: 130 }}>
          <div className="col-md-12">
            <form onSubmit={handleSubmit(onSubmit, onError)}>
              <div className="card card-outline">
                <div className="card-header">
                  <h3 className="card-title">
                    <i className="fas fa-edit" />
                    Payment
                  </h3>
                </div>
                <div className="card-body" style={{ paddingTop: 5 }}>
                  <div className="row">
                    <div className="col-md">
                      <div className="row">
                        <div className="col-md-4 col-sm-12">
                          <Label text="Student Code" required />
                          <AsyncSelect
                            name="codeSelected"
                            cacheOptions
                            defaultOptions
                            value={codeDefault}
                            loadOptions={loadCodeOption}
                            isMulti={true}
                            onChange={handleCode_OnChange.bind(this)}
                            ref={register("codeSelected", { required: true })}
                            isDisabled={method === "EDIT"}
                          />
                          {errors.codeSelected && (
                            <span>This field is required</span>
                          )}
                        </div>
                        <div className="col-md-2">
                          <Label text="Course Type" required />

                          <Select
                            name="courseTypeSelected"
                            value={courseTypeDefault}
                            options={courseTypeOptions}
                            onChange={handleCourseType_OnChange.bind(this)}
                            ref={register("courseTypeSelected", {
                              required: true,
                            })}
                            isDisabled={method === "EDIT"}
                          />
                          {errors.courseTypeSelected && (
                            <span>This field is required</span>
                          )}
                        </div>

                        <div className="col-md-4">
                          <Label text="Course" required={!enableCourse} />
                          <AsyncSelect
                            name="courseSelected"
                            cacheOptions
                            defaultOptions
                            placeholder={enableCourse ? null : "Not required"}
                            value={courseDefault}
                            loadOptions={loadCourseOption}
                            filterOptions={(option) => {
                              console.log("option: ", option);
                              return option;
                            }}
                            onChange={handleCourse_OnChange}
                            // ref={register("courseSelected", {
                            //   required: courseTypeDefault.label != "Open",
                            // })}
                            // isDisabled={
                            //   courseTypeDefault.label === "Open" ||
                            //   courseTypeSelected === null
                            // }
                            ref={register("courseSelected", {
                              required: enableCourse,
                            })}
                            isDisabled={!enableCourse || method === "EDIT"}
                          />

                          {errors.courseSelected && (
                            <span>This field is required</span>
                          )}
                        </div>
                      </div>
                      <hr />
                      <div className="row">
                        <div className="col-md-2">
                          <Label text="Payment Type" required />

                          <Select
                            name="paymentTypeSelected"
                            value={paymentTypeDefault}
                            options={paymentTypeOptions}
                            onChange={handlePaymentType_OnChange.bind(this)}
                            ref={register("paymentTypeSelected", {
                              required: true,
                            })}
                          />
                          {errors.paymentTypeSelected && (
                            <span>This field is required</span>
                          )}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-2">
                          <div className="form-group">
                            <Label text="Price" required />

                            <input
                              type="number"
                              name="price"
                              min="0"
                              // step="100"
                              className="form-control"
                              ref={register()}
                              onChange={handlePrice_OnChange}
                            />
                            {errors.price && (
                              <span>This field is required</span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-2">
                          <div className="form-group">
                            <Label text="Qty" required />

                            <input
                              type="number"
                              name="qty"
                              min="0"
                              defaultValue="0"
                              className="form-control"
                              ref={register()}
                              onChange={handleQty_OnChange}
                            />
                            {errors.qty && <span>This field is required</span>}
                          </div>
                        </div>

                        <div className="col-md-2">
                          <div className="form-group">
                            <Label text="Discount" />

                            <input
                              type="text"
                              name="discount"
                              defaultValue="0"
                              className="form-control"
                              ref={register()}
                              onChange={handleDiscount_OnChange}
                            />
                            <span style={{ color: "red" }}>{discountMsg}</span>
                            {errors.discount && (
                              <span>This field is required</span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-2">
                          <div className="form-group">
                            <Label text="Additional Fee" />

                            <input
                              type="number"
                              min={0}
                              name="fee"
                              className="form-control"
                              ref={register()}
                              onChange={handleFee_OnChange}
                              value={additionalFee}
                            />
                          </div>
                        </div>
                        <div className="col-md-2">
                          <div className="form-group" onClick={toggleTotalMode}>
                            <Label
                              text={
                                totalMode === MODE.default
                                  ? "Total"
                                  : "Total (+ Fee)"
                              }
                              required
                            />

                            <input
                              type="text"
                              name="total"
                              className="form-control"
                              ref={register()}
                              readOnly={true}
                              value={totalDefault}
                              // onChange={(e) => {
                              //   setTotalDefault(e.target.value);
                              //   setValue("total", e.target.value);
                              // }}
                            />
                            {errors.total && (
                              <span>This field is required</span>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-4">
                          <div className="form-group">
                            <Label text="Remark" />

                            <input
                              type="text"
                              name="remark"
                              className="form-control"
                              ref={register()}
                            />
                          </div>
                        </div>
                        <div className="col-md-2">
                          <div className="form-group">
                            <Label text="Expire" />
                            <DatePicker
                              name="expDate"
                              selected={expDate}
                              minDate={new Date()}
                              dateFormat="dd/MM/yyyy"
                              className="form-control"
                              onChange={(date) => setExpDate(date)}
                              disabled={enableCourse}
                            />
                            {enableCourse && <span>Expire date disabled</span>}
                          </div>
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="form-group">
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="received"
                              name="received"
                              ref={register()}
                            />
                            <Label text="Payment Received" />
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="closed"
                              name="closed"
                              ref={register()}
                            />
                            <Label text="Early Closed Payment" />
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div className="col-md-4">
                  <div className="row">
                    <div className="form-group col-md-12">
                      <div className="z-depth-1-half mb-5">
                        <div className="card">
                          <div className="card-img-top">
                            <div className="productImage">
                              <div className="img-wrapper">
                                <a className="thumb" href="#">
                                  <img src={src} alt={alt} />
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="card-footer">
                            <div className="custom-file">
                              <input
                                id="fileInput"
                                type="file"
                                className="custom-file-input"
                                accept="image/x-png,image/gif,image/jpeg"
                                onChange={handleChangeFile}
                              />
                              <label
                                className="custom-file-label"
                                htmlFor="customInput"
                              >
                                Choose file...
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
               */}
                  </div>
                </div>
                <div className="card-footer">
                  <button
                    type="submit"
                    className="btn btn-default mr-1"
                    disabled={discountMsg != ""}
                  >
                    Save
                  </button>
                  <button
                    type="button"
                    className="btn btn-default"
                    onClick={backOnClick}
                  >
                    Back
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Center>
    </Container>
  );
}
