import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Select from "react-select";
import DatePicker from "react-datepicker";
import axios from "axios";
import { AppConfig } from "../../../AppConfig";
import { getToken, getUserId } from "../../../@core/services";
import { datetimeToString } from "../../../@core/utilities/index";

export default function CourseTakingManage(props) {
  const { register, handleSubmit, setValue } = useForm();
  
  const [courseOptions, setCourseOptions] = useState(null);
  const [courseDefault, setCourseDefault] = useState();
  const [userOptions, setUserOptions] = useState(null);
  const [userDefault, setUserDefault] = useState();
  const [timeInCourseOptions, setTimeInCourseOptions] = useState(null);

  const [minDateValue, setMinDateValue] = useState('');
  const [maxDateValue, setMaxDateValue] = useState(null);

  const [timeCourseDate, setTimeCourseDate] = useState();

  useEffect(() => {
    getCourseOptions();
    getStudentOptions();
    //console.log(id);
  }, []);

  const backOnClick = () => {
    props.history.push(`${AppConfig.applicationPath}/courses`);
  };
  const getCourseOptions = async () => {
    const config = {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    };
    const urlAPI = `${AppConfig.apiURL}/api/courseTaking/searchByCourseName`;
    //console.log(urlAPI);
    const response = await axios.get(urlAPI, config);
    
    const data = response.data;
    //console.log(response.data);
    if (data !== undefined) {
      const options = data.map((d) => ({
        value: d.displayName,
        label: d.displayName,
      }));
      //console.log(options);
      setCourseOptions(options);
    } else {
      setCourseOptions(null);
    }
  };
  const getStudentOptions = async () => {
    const config = {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    };
    const urlAPI = `${AppConfig.apiURL}/api/courseTaking/searchByStudent`;    
    const response = await axios.get(urlAPI, config);
    
    const data = response.data;    
    if (data !== undefined) {
      const options = data.map((d) => ({
        value: d.id,
        label: d.displayName,
      }));
      //console.log(options);
      setUserOptions(options);
    } else {
      setUserOptions(null);
    }
  };
  const getTimeInCourseOptions = async (value) => {
    const config = {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    };
    const urlAPI = `${AppConfig.apiURL}/api/courseTaking/searchByCourseNameDetail`;
    const dataSubmit = {
      course: value      
    };
    console.log(dataSubmit);
    const response = await axios.post(urlAPI, dataSubmit, config);
    console.log(response);
    const data = response.data;
    if (data !== undefined) {
      const options = data.map((d) => ({
        value: d.id,
        label: datetimeToString(d.startTime) + '-' + datetimeToString(d.endTime),
      }));      
      setTimeInCourseOptions(options);
    } else {
      setTimeInCourseOptions(null);
    }
  };
  const handleCourse_OnChange = (e) => {    
    //console.log(e.value);
    getTimeInCourseOptions(e.value);
  };
  const handleTimeCourse_OnChange = (e) => {
    setMinDateValue('01-05-2021');
    setMaxDateValue('01-05-2021');
  };
  const onSubmit = async (data) => {
    return false;
  };
  return (
    <div className="row" style={{ marginBottom: 130 }}>
      <div className="col-12">
        <form>
          <div className="card card-outline">
            <div className="card-header">
              <h3 className="card-title">
                <i className="fas fa-edit" />
                Course Taking
              </h3>
            </div>
            <div className="card-body" style={{ paddingTop: 5 }}>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Course </label>
                    <Select name="courseNameSelected"                      
                      value={courseDefault}
                      options={courseOptions}
                      onChange={handleCourse_OnChange.bind(this)}
                     />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Start Times - End Times</label>
                    <Select name="timeSelected"
                      options={timeInCourseOptions}
                      onChange={handleTimeCourse_OnChange.bind(this)}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Student</label>
                    <Select name="userNameSelected"
                      value={userDefault}
                      options={userOptions}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <label>Check In</label>
                  <div className="form-group">
                    <DatePicker
                      name="startDate"
                      autoComplete="off"
                      dateFormat="dd/MM/yyyy HH:mm"
                      className="form-control"
                      minDate={new Date(minDateValue)}
                      maxDate={new Date(maxDateValue)}
                      showTimeSelect
                      selected={timeCourseDate}
                      onChange={(date) => setTimeCourseDate(date)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="card-footer">
              <button type="submit" className="btn btn-default mr-1">
                Save
              </button>
              <button
                type="button"
                className="btn btn-default"
                onClick={backOnClick}
              >
                Back
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
