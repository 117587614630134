import React, {
  forwardRef,
  useState,
  useEffect,
  useImperativeHandle,
} from "react";
import axios from "axios";
import DataTable from "react-data-table-component";
import { Button } from "@mantine/core";
import { IconDatabaseExport } from "@tabler/icons";
import { getToken } from "../services/Authenticate";
import { AppConfig } from "../../AppConfig";
import { CSVLink } from "react-csv";
import { datetime2ToString } from "../utilities";
import { Loader } from "@mantine/core";
const AdvancedPaginationTable = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    doSearch() {
      // fetchData(currentPage);
    },
    doSearchCommon(searchValue) {
      fetchDataCommon(searchValue);
    },
    doAdvanceSearch(filter) {
      // fetchData(currentPage, filter);
    },
    setFilter(filter) {
      setCurrentFilter(filter);
      setCurrentPage(1);
    },
    setHeader(headers) {
      setCSVHeader(headers);
    },
  }));

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(100);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentFilter, setCurrentFilter] = useState(null);

  const [csvHeader, setCSVHeader] = useState(null);

  const fetchData = async () => {
    setLoading(true);

    try {
      const config = {
        headers: {
          Authorization: "Bearer " + getToken(),
        },
      };
      const urlAPI = `${AppConfig.apiURL}${props.apiURL}?page=${currentPage}&size=${perPage}`;
      const response = await axios.post(urlAPI, currentFilter, config);
      console.log(response.data.data.rows);
      if (!!!props.postFilter) {
        setData(response.data.data.rows);
      } else {
        setData(response.data.data.rows.filter(props.postFilter));
      }
      setTotalRows(response.data.data.count);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };
  const fetchDataCommon = async (dataSubmit) => {
    setLoading(true);

    const config = {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    };
    const urlAPI = `${AppConfig.apiURL}${props.apiURL}?page=${currentPage}&size=${perPage}`;
    console.log(urlAPI);
    const response = await axios.post(urlAPI, dataSubmit, config);

    setData(response.data.data.rows);

    setTotalRows(response.data.data.count);
    setLoading(false);
  };
  useEffect(() => {
    var isCancelled = false;
    if (!isCancelled != null && currentFilter) {
      console.log("fetching...: ", currentFilter);
      // console.log(currentFilter);
      fetchData();
    }
    return () => {
      isCancelled = true;
    };
  }, [currentPage, perPage, currentFilter]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPage(newPerPage);
  };
  return (
    <>
      {(props.showExport || props.showExport === undefined) && (
        <CSVLink
          data={data}
          filename={`${props.title}_${datetime2ToString(new Date())}.csv`}
          // className="btn btn-info btn-flat"
          // type="button"
          headers={csvHeader}
          // style={{ backgroundColor: "#869099" }}
        >
          {/* <i className="fas fa-file-export"></i>&nbsp; Export */}
          <Button
            style={{ marginLeft: 5 }}
            variant="light"
            leftIcon={<IconDatabaseExport />}
          >
            Export
          </Button>
        </CSVLink>
      )}

      <DataTable
        title={props.title}
        columns={props.columns}
        data={data}
        noHeader={props.noHeader}
        progressPending={loading}
        progressComponent={<Loader size="lg" />}
        sortable={false}
        pagination
        paginationServer
        paginationTotalRows={totalRows}
        paginationDefaultPage={currentPage}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        selectableRows={props.isSelectableRows}
      />
    </>
  );
});

export default AdvancedPaginationTable;
