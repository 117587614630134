import React, { useMemo, useRef, useEffect, useState } from "react";
import Swal from "sweetalert2";
import AdvancedPaginationTable from "../../../@core/components/AdvancedPaginationTable";
import { AppConfig } from "../../../AppConfig";
import UserService from "../../../services/master/UserService";
import { format } from "date-fns";
import { toCSVHeader } from "../../../@core/utilities";
import AlertModal from "./AlertModal";
import axios from "axios";
import { getToken, getUserInfo } from "../../../@core/services";
import { IconCirclePlus } from "@tabler/icons";
import { Button, TextInput } from "@mantine/core";
import { useDebouncedState } from "@mantine/hooks";
import SearchBox from "../../../@core/components/SearchBox";
import SummaryModal from "./SummaryModal";
export default function UserList(props) {
  const childRef = useRef();
  const [searchBox, setSearchBox] = useDebouncedState("", 200);
  const [studentId, setStudentId] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [info, setInfo] = useState(null);
  const [isDev, setDev] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const validateDevPermission = async () => {
    const user = await getUserInfo();
    if (user.username === "dev" && user.roleName.toLowerCase() === "admin") {
      setDev(true);
    }
  };
  const showSummary = async (row) => {
    setStudentId(row.id);
    await getSummary(row);
  };
  const getSummary = async (row) => {
    const urlAPI = `${AppConfig.apiURL}/api/courseTaking/summary`;

    const config = {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    };
    const response = await axios.post(urlAPI, { studentId: row.id }, config);
    console.log("response: ", response.data);
    setInfo({
      record: response.data,
      student: {
        code: row.code,
        firstName: row.firstName,
        nickname: row.nickname,
      },
    });
  };
  useEffect(() => {
    if (info != null) {
      setOpenModal(true);
    }
    return () => {};
  }, [info]);

  useEffect(() => {
    var isCancelled = false;
    if (!isCancelled) {
      childRef.current.setFilter({ searchBox });
    }
    return () => {
      isCancelled = true;
    };
  }, [searchBox]);
  useEffect(() => {
    validateDevPermission();
    childRef.current.setHeader(toCSVHeader(csvCols));
    return () => {};
  }, []);
  const csvCols = useMemo(() => [
    { name: "Code", selector: "code" },
    {
      name: "Gender",
      selector: "gender",
    },

    {
      name: "First name",
      selector: "firstName",
    },
    {
      name: "Last name",
      selector: "lastName",
    },
    {
      name: "Nickname",
      selector: "nickname",
    },
    {
      name: "Contact Number",
      selector: "contancNo",
    },
    {
      name: "Birth Date",
      selector: "dateOfBirth",
    },
    {
      name: "E-mail",
      selector: "email",
    },
    {
      name: "Channel",
      selector: "questionKnowHarlem",
    },
  ]);
  const columns = useMemo(() => [
    {
      cell: (row) => (
        // <div onClick={async (e) => await showSummary(row)}>{row.code}</div>
        <div onClick={() => setSelectedRow(row)}>{row.code}</div>
      ),
    },
    {
      name: "Gender",
      selector: "gender",
      sortable: false,
    },

    {
      name: "First name",
      selector: "firstName",
      sortable: false,
    },
    {
      name: "Last name",
      selector: "lastName",
      sortable: false,
    },
    {
      name: "Nickname",
      selector: "nickname",
      sortable: false,
    },
    {
      name: "Contact Number",
      selector: "contancNo",
      sortable: false,
    },
    {
      name: "Birth Date",
      sortable: false,
      cell: (row) => (
        <div>{format(new Date(row.dateOfBirth), "dd/MM/yyyy")}</div>
      ),
    },
    {
      name: "E-mail",
      selector: "email",
      sortable: false,
    },
    {
      name: "Channel",
      selector: "questionKnowHarlem",
      sortable: false,
    },
    {
      cell: (row) => {
        return (
          <div>
            <a
              href="#empty"
              onClick={(e) => {
                e.preventDefault();
                editOnclick(row);
              }}
            >
              <i className="fas fa-pencil-alt mr-3"></i>
            </a>
            <a
              href="#empty"
              onClick={(e) => {
                e.preventDefault();
                deleteOnClick(row);
              }}
            >
              <i className="fas fa-trash-alt"></i>
            </a>
          </div>
        );
      },
    },
  ]);
  const newOnclick = (row) => {
    props.history.push(`${AppConfig.applicationPath}/users/new`);
  };
  const editOnclick = (row) => {
    console.log(row.id);
    props.history.push(`${AppConfig.applicationPath}/users/edit/${row.id}`);
  };
  const deleteData = async (id) => {
    const dataSubmit = {
      id: id,
    };
    const handler = new UserService();
    try {
      await handler.delete(dataSubmit);
      Swal.fire("Deleted!", "Your file has been deleted.", "success");
      childRef.current.doAdvanceSearch({ searchBox });
    } catch (error) {
      console.log("error");
      Swal.fire("Deleted!", "Delete fail.", "error");
    }
  };
  const deleteOnClick = async (row) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Are you sure you want to delete this information?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Please Wait !",
          html: "System processing", // add html attribute if you want or remove
          allowOutsideClick: false,
          showCancelButton: false,
          showConfirmButton: false,
          willOpen: () => {
            Swal.showLoading();
            try {
              deleteData(row.id);
            } catch (error) {
              console.log("error");
            }
            childRef.current.setFilter({ searchBox });

            Swal.close();
          },
        });
      }
    });
  };

  return (
    <>
      {/** Modal for showing remaining course: forgot to remove this on production so i left it there for admin */}
      {/* <AlertModal
        data={info}
        isOpen={openModal}
        onClose={() => {
          setInfo(null);
          setOpenModal(false);
        }}
      /> */}
      <SummaryModal
        isOpen={selectedRow != null}
        row={selectedRow}
        onClose={() => setSelectedRow(null)}
      />
      <section className="content" id="user">
        <div className="container-fluid">
          <div className="row mt-2 mb-2">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h3 className="card-title">User</h3>

                  <div className="card-tools">
                    <SearchBox
                      defaultValue={searchBox}
                      onChange={(e) => setSearchBox(e.target.value)}
                    />
                    {/* <div className="input-group input-group-sm">
                     
                      <span className="input-group-append">
                      <button
                        type="button"
                        onClick={searchOnClick}
                        className="btn btn-info btn-flat mr-1"
                        style={{ backgroundColor: "#869099" }}
                      >
                        Search!
                      </button>
                    </span> 
                    </div> */}
                  </div>
                </div>
                {/* /.card-header */}
                <div className="card-body">
                  <Button
                    leftIcon={<IconCirclePlus />}
                    onClick={newOnclick}
                    color="blue"
                  >
                    New
                  </Button>

                  <AdvancedPaginationTable
                    ref={childRef}
                    title="User"
                    columns={columns}
                    apiURL="\api\users"
                    isSelectableRows={false}
                    noHeader={true}
                  />
                </div>
                {/* /.card-body */}
              </div>
            </div>
            {/* /.col */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container-fluid */}
      </section>
    </>
  );
}
