import React from "react";
import ReportService from "../../../services/report/ReportService";
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
export default function CoursesReport() {
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';
  const btnDonwlodOnClick = async () => {
    const handler = new ReportService();
    const csv = await handler.downloadCoursesReport();
    //console.log(csv);

    exportToCSV(csv, 'test');
  };
  const exportToCSV = (csvData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], {type: fileType});
    FileSaver.saveAs(data, fileName + fileExtension);
}

  return (
    <div>
      <button type="button" className="btn btn-primary" onClick={ btnDonwlodOnClick }>Download</button>
    </div>
  );
}
