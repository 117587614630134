import React from "react";

import { BrowserRouter, Switch } from "react-router-dom";
import PrivateRoute from "./app/@core/components/PrivateRoute";
import PublicRoute from "./app/@core/components/PublicRoute";
import Backoffice from "./app/screens/Backoffice";
import Login from "./app/screens/Login";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import { MantineProvider } from "@mantine/core";

import th from "date-fns/locale/th";
registerLocale("th", th);
setDefaultLocale("th");
function App(props) {
  return (
    <MantineProvider
      withGlobalStyles
      withNormalizeCSS
      theme={{
        // THIS WILL RESUlT IN ERROR, DO NOT DO THIS
        primaryColor: "gray",
      }}
    >
      <BrowserRouter>
        <Switch>
          <PublicRoute restricted={true} component={Login} path="/" exact />
          <PrivateRoute component={Backoffice} path="/page" />
        </Switch>
      </BrowserRouter>
    </MantineProvider>
  );
}

export default App;
