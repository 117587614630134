import { DatePicker } from "@mantine/dates";
import { IconCalendarTime } from "@tabler/icons";
import React from "react";

const DateInput = ({ placehodler = "Select Date", ...rest }) => {
  return (
    <DatePicker
      placeholder={placehodler}
      inputFormat="DD/MM/YYYY"
      icon={<IconCalendarTime />}
      {...rest}
    />
  );
};

export default DateInput;
