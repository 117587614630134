import { useHistory } from "react-router-dom";
import { AppConfig } from '../../AppConfig';

const TOKEN_KEY = 'jwt';
export function isLogin() {
    if (localStorage.getItem(AppConfig.storageNameToken)) {
        return true;
    }
    return false;
}
export function login() {
    localStorage.setItem(AppConfig.storageNameToken, 'TestLogin');
}
export function getToken() {
    return localStorage.getItem(AppConfig.storageNameToken);
}
export function setToken(token){
    localStorage.setItem(AppConfig.storageNameToken, token);
}
export function setUserInfo(value){
    localStorage.setItem(AppConfig.storageNameUserInfo, JSON.stringify(value));
}
export function getUserInfo(){
    //return JSON.parse(localStorage.getItem(AppConfig.storageNameUserInfo));
    const value = JSON.parse(localStorage.getItem(AppConfig.storageNameUserInfo));
    //console.log(value);
    return value;
}
export function logout(){
    localStorage.removeItem(AppConfig.storageNameToken);
    window.location.href = '/';
}
export function getUserId(){
    const value = JSON.parse(localStorage.getItem(AppConfig.storageNameUserInfo));
    return value.id;
}