const type = [{ name: "open" }, { name: "trial" }, { name: "one" }];

export const createSubmitObject = (
  mainObject,
  typeRefs,
  oneClass = null,
  trialClass = null
) => {
  var mainType = typeRefs.find((t) => t.value === mainObject.courseTypeId);
  if (mainType.label.toLowerCase() != "open") {
    return { isOpen: false, data: mainObject };
  }
  var one = typeRefs.find((t) => t.label.toLowerCase() === "one");
  var trial = typeRefs.find((t) => t.label.toLowerCase() === "trial");
  var submitObject;
  if (oneClass === null && trialClass === null)
    submitObject = {
      isOpen: true,
      data: {
        open: mainObject,
        one: { ...mainObject, courseTypeId: one.value },
        trial: { ...mainObject, courseTypeId: trial.value },
      },
    };
  else
    submitObject = {
      isOpen: true,
      data: {
        open: mainObject,
        one: { ...mainObject, courseTypeId: one.value, courseId: oneClass.ID },
        trial: {
          ...mainObject,
          courseTypeId: trial.value,
          courseId: trialClass.ID,
        },
      },
    };
  return submitObject;
};
