import React, { useMemo, useRef, useEffect } from "react";
import AdvancedPaginationTable from "../../../@core/components/AdvancedPaginationTable";
import { datetimeToString } from "../../../@core/utilities";
import { AppConfig } from "../../../AppConfig";

//import { datetimeToString, numberFormat } from "../../../@core/utilities/index";
export default function UserRoleList(props) {
  const childRef = useRef();

  const searchOnClick = () => childRef.current.doSearch();

  const columns = useMemo(() => [
    /*
    {
      name: "Id",
      selector: "id",
      sortable: true,
    },
    */
    {
      name: "Name",
      selector: "name",
      sortable: false,
    },
    /*
    {
      name: "Create Date",
      sortable: false,
      cell: (row) => <div>{datetimeToString(row.createDate)}</div>,
    },
    {
      name: "Create By",
      selector: "createBy",
      sortable: false,
    },
    {
      name: "Modify Date",
      selector: "modifyDate",
      sortable: false,
    },
    {
      name: "Modify By",
      selector: "modifyBy",
      sortable: false,
    },
    {
      name: "Active",
      cell: (row) => <div>{row.active ? "true" : "false"}</div>,
      sortable: false,
    },
    */
    /*
    {
      cell: (row) => (
        <a
          href="#empty"
          onClick={(e) => {
            e.preventDefault();
            editOnclick(row);
          }}
        >
          <i className="fas fa-pencil-alt"></i>
        </a>
      ),
    },
    */
    /*
        {
          // eslint-disable-next-line react/button-has-type
          cell: (row) => <button onClick={handleDelete(row)}>Delete</button>,
        },
        */
  ]);
  const newOnclick = (row) => {
    props.history.push(`${AppConfig.applicationPath}/courses/new`);
  };

  const editOnclick = (row) => {
    //console.log(row.id);
    props.history.push(`${AppConfig.applicationPath}/courses/edit/${row.id}`);
  };
  useEffect(() => {
    var isCancelled = false;
    if (!isCancelled) {
      childRef.current.setFilter({ searchBox: "" });
    }
    return () => {
      isCancelled = true;
    };
  }, []);
  return (
    <section className="content">
      <div className="container-fluid">
        <div className="row mt-2 mb-2">
          <div className="col-12">
            <div className="card">
              <div className="card-header">
                <h3 className="card-title">User Role</h3>
              </div>
              {/* /.card-header */}
              <div className="card-body">
                <AdvancedPaginationTable
                  ref={childRef}
                  title="Courses"
                  columns={columns}
                  apiURL="\api\userRoles"
                  isSelectableRows={false}
                  noHeader={true}
                />
              </div>
              {/* /.card-body */}
            </div>
          </div>
          {/* /.col */}
        </div>
        {/* /.row */}
      </div>
      {/* /.container-fluid */}
    </section>
  );
}
