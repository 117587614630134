import axios from "axios";
import { getToken } from "../../@core/services";
import { AppConfig } from "../../AppConfig";

class PaymentService {
  constructor() {}
  async insert(data) {
    const urlAPI = `${AppConfig.apiURL}/api/payments/insert`;
    const config = {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    };
    const response = await axios.post(urlAPI, data, config);
    //console.log(response);
    if (response.status === 200) {
      return { status: "00" };
    }
  }

  async update(data) {
    const urlAPI = `${AppConfig.apiURL}/api/payments/update`;
    const config = {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    };
    const response = await axios.post(urlAPI, data, config);
    console.log(response);
    if (response.status === 200) {
      return { status: "00" };
    }
  }
  async get(id) {
    const urlAPI = `${AppConfig.apiURL}/api/payments/${id}`;
    const config = {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    };
    const response = await axios.get(urlAPI, config);
    console.log(response);
    if (response.status === 200) {
      return response.data;
    } else return null;
  }
  async delete(data) {
    const urlAPI = `${AppConfig.apiURL}/api/payments/delete`;
    const config = {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    };
    let response = null;
    try {
      response = await axios.post(urlAPI, data, config);
      if (response.status === 200) {
        return { status: "00" };
      }
    } catch (error) {
      throw error;
    }
  }
}

export default PaymentService;
