import { TextInput } from "@mantine/core";
import { useDebouncedState } from "@mantine/hooks";
import React, { useMemo, useRef, useEffect, useState } from "react";
import Swal from "sweetalert2";
import AdvancedPaginationTable from "../../../@core/components/AdvancedPaginationTable";
import SearchBox from "../../../@core/components/SearchBox";
import { getUserInfo } from "../../../@core/services";

import { formatUTCDateTime } from "../../../@core/utilities/index";
import CourseTakingService from "../../../services/process/CourseTakingService";
export default function CourseTakingList(props) {
  const [searchBox, setSearchBox] = useDebouncedState("", 200);
  const [enableDelete, setEnableDelete] = useState(false);
  const validateDeletePermission = async () => {
    const user = await getUserInfo();
    // username: "dev" is for developer who can individually delete coursetaking record
    if (user.username === "dev" && user.roleName.toLowerCase() === "admin") {
      setEnableDelete(true);
    }
  };
  useEffect(() => {
    var isCancelled = false;
    if (!isCancelled) {
      validateDeletePermission();
      childRef.current.setFilter({ searchBox });
    }
    return () => {
      isCancelled = true;
    };
  }, [searchBox]);
  const childRef = useRef();

  const columns = useMemo(() => [
    {
      name: "Name",
      selector: "name",
      sortable: false,
    },

    {
      name: "Course Type Name",
      selector: "courseTypeName",
      sortable: false,
    },
    {
      name: "Start Time",
      cell: (row) => <div>{formatUTCDateTime(row.starttime)}</div>,
      sortable: false,
    },
    {
      name: "End Time",
      cell: (row) => <div>{formatUTCDateTime(row.endTime)}</div>,
      sortable: false,
    },
    {
      name: "Day Of Week",
      selector: "dayOfWeek",
      sortable: false,
    },
    {
      name: "Student Code",
      selector: "st_code",
      sortable: false,
    },
    {
      name: "Student Name",
      selector: "st_firstname",
      sortable: false,
    },
    {
      name: "Student Nickname",
      selector: "st_nickname",
      sortable: false,
    },
    {
      name: "Instructor Code",
      selector: "ins_code",
      sortable: false,
    },
    {
      name: "Instructor Name",
      selector: "ins_firstname",
      sortable: false,
    },
    {
      name: "Instructor Nickname",
      selector: "ins_nickname",
      sortable: false,
    },
    {
      name: "Check In",
      cell: (row) => <div>{formatUTCDateTime(row.checkIn)}</div>,
      sortable: false,
    },
    {
      cell: (row) => {
        if (!enableDelete || row === undefined) return <></>;
        return (
          <div>
            <a
              href="#empty"
              onClick={(e) => {
                e.preventDefault();
                deleteOnClick(row);
              }}
            >
              <i className="fas fa-trash-alt"></i>
            </a>
          </div>
        );
      },
    },
  ]);
  const deleteData = async (id) => {
    const dataSubmit = {
      courseTakingId: id,
    };
    const handler = new CourseTakingService();

    try {
      await handler.delete(dataSubmit);
      Swal.fire("Deleted!", "Your file has been deleted.", "success");
      childRef.current.setFilter({ searchBox });
    } catch (error) {
      console.log("error");
      Swal.fire("Deleted!", "Delete fail.", "error");
    }
  };
  const deleteOnClick = async (row) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Are you sure you want to delete this information?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Please Wait !",
          html: "System processing", // add html attribute if you want or remove
          allowOutsideClick: false,
          showCancelButton: false,
          showConfirmButton: false,
          willOpen: () => {
            Swal.showLoading();
            try {
              deleteData(row.id);
            } catch (error) {
              console.log("error");
            }

            Swal.close();
          },
        });
      }
    });
  };
  return (
    <section className="content">
      <div className="container-fluid">
        <div className="row mt-1 mb-1">
          <div className="col-12">
            <div className="card">
              <div className="card-header">
                <h3 className="card-title">Course Taking</h3>
                <div className="card-tools">
                  <SearchBox
                    defaultValue={searchBox}
                    onChange={(e) => setSearchBox(e.target.value)}
                  />
                  {/* <div className="input-group input-group-sm">
                    <input
                      type="text"
                      className="form-control"
                      value={searchBox}
                      onChange={(e) => setSearchBox(e.target.value)}
                      placeholder="Search"
                    />
                  </div> */}
                </div>
              </div>
              {/* /.card-header */}
              <div className="card-body">
                <AdvancedPaginationTable
                  ref={childRef}
                  title=""
                  columns={columns}
                  apiURL="\api\coursetaking"
                  isSelectableRows={false}
                  noHeader={true}
                />
              </div>
              {/* /.card-body */}
            </div>
          </div>
          {/* /.col */}
        </div>
        {/* /.row */}
      </div>
      {/* /.container-fluid */}
    </section>
  );
}
