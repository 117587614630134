import React, { useMemo, useRef, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { AppConfig } from "../../../AppConfig";
import Label from "../../../@core/components/Label";
import DatePicker from "react-datepicker";
import Swal from "sweetalert2/dist/sweetalert2";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import { useParams } from "react-router";
import BookingService from "../../../services/process/BookingService";
import CourseService from "../../../services/master/CourseService";
import UserService from "../../../services/master/UserService";
import { isNullEmpty, getTimezoneValue } from "../../../@core/utilities";
import { format, utcToZonedTime } from "date-fns-tz";
import { getUserId } from "../../../@core/services";
import { Container, Center } from "@mantine/core";
export default function BookingManage(props) {
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    clearErrors,
    watch,
    formState: { errors },
  } = useForm();

  const { id } = useParams();
  const [method, setMethod] = useState("NEW");
  const [bookingDate, setBookingDate] = useState(new Date());
  const [courseDefault, setCourseDefault] = useState();
  const [courseOptions, setCourseOptions] = useState(null);
  const [courseSelected, setCourseSelected] = useState("");
  const [instructorId, setInstructorId] = useState("");

  const [studentDefault, setStudentDefault] = useState("");
  const [studentSelected, setStudentSelected] = useState("");

  let messageError = "";
  useEffect(() => {
    async function fetchData() {
      const handler = new BookingService();
      const objinfo = await handler.get(id);
      console.log(objinfo);
      if (objinfo !== null) {
        const timeZoneValue = getTimezoneValue();
        const bookingDateValue = utcToZonedTime(
          new Date(objinfo.date),
          timeZoneValue
        );

        setBookingDate(bookingDateValue);
        await getCourseOptions(objinfo.courseId);

        setValue("studentSelected", [
          {
            value: objinfo.studentId,
            label: `${objinfo.studentCode}: ${objinfo.studentFirstName}`,
          },
        ]);
        setStudentSelected([
          {
            value: objinfo.studentId,
            label: `${objinfo.studentCode}: ${objinfo.studentFirstName}`,
          },
        ]);
        setValue("studentDefault", [
          {
            value: objinfo.studentId,
            label: `${objinfo.studentCode}: ${objinfo.studentFirstName}`,
          },
        ]);
        setStudentDefault([
          {
            value: objinfo.studentId,
            label: `${objinfo.studentCode}: ${objinfo.studentFirstName}`,
          },
        ]);
      } else {
        Swal.fire({
          title: "Error!",
          text: "Data not found",
          icon: "error",
          confirmButtonText: "Ok",
        });
        props.history.push(`${AppConfig.applicationPath}/booking`);
      }
    }
    if (!isNullEmpty(id)) {
      setMethod("EDIT");
      Swal.fire({
        title: "Please Wait !",
        html: "System processing",
        allowOutsideClick: false,
        showCancelButton: false,
        showConfirmButton: false,
        willOpen: () => {
          Swal.showLoading();
          fetchData();
          Swal.close();
        },
      });
    } else {
      getCourseOptions();
    }
  }, []);

  const backOnClick = () => {
    props.history.push(`${AppConfig.applicationPath}/booking`);
  };
  const handleCourse_OnChange = (e) => {
    setCourseSelected(e.value);
    setCourseDefault({
      value: e.value,
      label: e.label,
    });
    setInstructorId(e.instructorId);
  };
  const handleStudent_OnChange = async (newValue) => {
    setStudentDefault(newValue);
    //setStudentSelected("studentSelected", newValue);
    setValue("studentSelected", newValue);
    setStudentSelected(newValue);
    return newValue;
  };
  const getCourseOptions = async (id = null) => {
    const handler = new CourseService();
    const data = await handler.getCourses();
    // console.log(data.data.rows);
    const courses = data.data.rows;
    if (courses !== undefined) {
      const options = courses
        .map((d) => ({
          value: d.id,
          label: d.courseName,
          instructorId: d.instructorId,
          courseTypeId: d.courseTypeId,
        }))
        .filter((value) => value.courseTypeId === 1);

      if (options === undefined) setCourseOptions(null);
      else {
        setCourseOptions(options);
        if (!!id) {
          var ins = options.find((i) => i.value === id);
          setCourseDefault(ins);
          setValue("courseSelected", ins.value);
          setCourseSelected(ins.value);
        }
      }
    } else {
      setCourseOptions(null);
    }
  };
  const loadStudentOption = (inputValue, callback) => {
    var result = new Promise((resolve) => {
      resolve(getStudentOption(inputValue));
    });
    result.then((res) => {
      callback(res);
    });
  };
  const getStudentOption = async (studentCode) => {
    const handler = new UserService();
    const data = await handler.searchByStudent(studentCode);
    if (data !== undefined) {
      var options = data.map((d) => {
        var name = "-";
        if (d.lastName != "" || d.lastName != null) name = d.lastName;
        if (d.firstName != "" || d.firstName != null) name = d.firstName;
        if (d.nickname != "" || d.nickname != null) name = d.nickname;

        if (d.firstName != "" && d.firstName != null) {
          name = `${name} (${d.firstName})`;
        }
        return {
          value: d.id,
          label: `${d.code}: ${name}`,
        };
      });
      return options;
    } else {
      return [];
    }
  };
  const validateData = (data) => {
    /*
		console.log(courseTypeSelected);
		console.log(instructorSelected);
		console.log(docDate);
		*/
    console.log(data);
    let result = true;
    messageError = "";
    //console.log(format(docDate, "yyyy-MM-dd"));

    return result;
  };
  const onSubmit = async (data) => {
    if (!validateData(data)) {
      Swal.fire({
        title: "Error!",
        html: messageError,
        icon: "error",
        confirmButtonText: "Ok",
      });
      return false;
    }
    const handler = new BookingService();
    let result = null;
    if (method === "NEW") {
      //console.log("NEW");
      const dataSubmit = {
        date: format(bookingDate, "yyyy-MM-dd"),
        courseId: courseSelected,
        instructorId: instructorId,
        studentId: studentSelected[0].value,
        maxSeat: 1,
        userId: getUserId(),
      };
      console.log(dataSubmit);
      result = await handler.insert(dataSubmit);
      console.log(result);
    } else if (method === "EDIT") {
      const dataSubmit = {
        bookingId: id,
        date: format(bookingDate, "yyyy-MM-dd"),
        courseId: courseSelected,
        instructorId: instructorId,
        studentId: studentSelected[0].value,
        maxSeat: 1,
        userId: getUserId(),
      };
      result = await handler.update();
    }
    if (result !== null) {
      if (result.status === "00") {
        Swal.fire({
          icon: "info",
          title: "success",
          html: "Save data success.",
        }).then(() => {
          props.history.push(`${AppConfig.applicationPath}/booking`);
        });
      }
    }
  };
  const onError = (errors, e) => console.log(errors, e);
  return (
    <Container size="md">
      <Center style={{ paddingTop: 10 }}>
        <div className="row" style={{ marginBottom: 130 }}>
          <div className="col-12">
            <form onSubmit={handleSubmit(onSubmit, onError)}>
              <div className="card card-outline">
                <div className="card-header">
                  <h3 className="card-title">
                    <i className="fas fa-edit" />
                    Booking
                  </h3>
                </div>
                <div className="card-body" style={{ paddingTop: 5 }}>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <Label text="Date" required />
                        <DatePicker
                          name="bookingDate"
                          selected={bookingDate}
                          minDate={new Date()}
                          dateFormat="yyyy-MM-dd"
                          className="form-control"
                          onChange={(date) => setBookingDate(date)}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <Label text="Course" required />
                        <Select
                          name="courseSelected"
                          value={courseDefault}
                          options={courseOptions}
                          onChange={handleCourse_OnChange.bind(this)}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <Label text="Student" required />
                      <AsyncSelect
                        name="studentSelected"
                        cacheOptions
                        defaultOptions
                        value={studentDefault}
                        loadOptions={loadStudentOption}
                        isMulti={true}
                        onChange={handleStudent_OnChange.bind(this)}
                        ref={register("studentSelected", { required: true })}
                        isDisabled={method === "EDIT"}
                      />
                      {errors.codeSelected && (
                        <span>This field is required</span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="card-footer">
                  <button type="submit" className="btn btn-default mr-1">
                    Save
                  </button>
                  <button
                    type="button"
                    className="btn btn-default"
                    onClick={backOnClick}
                  >
                    Back
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Center>
    </Container>
  );
}
