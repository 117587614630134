import {
  Button,
  Center,
  SimpleGrid,
  Space,
  Stack,
  Text,
  Textarea,
  TextInput,
  ThemeIcon,
} from "@mantine/core";
import { IconDeviceFloppy, IconLockOpen } from "@tabler/icons";
import React, { useState } from "react";
import { useEffect } from "react";

const InstructorProfileForm = ({ getDefault, setProfile, onClose }) => {
  const [state, setState] = useState({
    aka: "",
    fullname: "",
    style: "",
    detail: "",
  });
  function handleChange(e) {
    setState({ ...state, [e.target.name]: e.target.value });
  }
  //   useEffect(() => {
  //     setProfile(`${state.aka}/${state.fullname}/${state.detail}`);
  //   }, [state]);
  useEffect(() => {
    const defaultValue = getDefault();
    console.log(defaultValue);
    const splitted = defaultValue.split("/");
    setState({
      ...state,
      aka: splitted[0],
      fullname: splitted[1],
      style: splitted[2],
      detail: splitted[3],
    });
  }, []);

  return (
    <Stack>
      <SimpleGrid cols={3}>
        <Text fz="lg" fw={700}>
          Profile Editor
        </Text>
        <Space />
        <Button
          leftIcon={<IconDeviceFloppy />}
          onClick={() => {
            setProfile(
              `${state.aka}/${state.fullname}/${state.style}/${state.detail}`
            );
            onClose();
          }}
        >
          Save
        </Button>
      </SimpleGrid>

      {/* <Center> */}
      <TextInput
        value={state.aka}
        name="aka"
        label="Nickname/AKA"
        onChange={handleChange}
      />
      <TextInput
        value={state.fullname}
        name="fullname"
        label="Fullname"
        onChange={handleChange}
      />
      <TextInput
        value={state.style}
        name="style"
        label="Style"
        onChange={handleChange}
      />
      <Textarea
        value={state.detail}
        name="detail"
        label="Profie Detail"
        onChange={handleChange}
        autosize
        maxRows={6}
        minRows={3}
      />

      {/* </Center> */}
    </Stack>
  );
};

export default InstructorProfileForm;
