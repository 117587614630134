import axios from "axios";
import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { getToken } from "../../../@core/services";
import { AppConfig } from "../../../AppConfig";
import { QRCodeSVG } from "qrcode.react";
import { Center, Loader, LoadingOverlay, Text } from "@mantine/core";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};
export default function SummaryModal({ row, isOpen, onClose }) {
  const [showMessage, setShowMessage] = useState(false);
  const [state, setState] = useState(null);

  const [courseTypeRefs, setCourseTypeRefs] = useState(null);
  const [instructorRefs, setInstructorRefs] = useState(null);
  const [info, setInfo] = useState(null);
  const getCourseType = async () => {
    const config = {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    };
    const urlAPI = `${AppConfig.apiURL}/api/courseTypes?page=${1}&size=${200}`;
    const response = await axios.post(urlAPI, null, config);
    setCourseTypeRefs(response.data.data.rows);
    console.log("done fetching courseTypes");
    return response;
  };
  const getInstructors = async () => {
    const config = {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    };
    const urlAPI = `${
      AppConfig.apiURL
    }/api/users/instructor?page=${1}&size=${2000}`;
    const response = await axios.post(urlAPI, null, config);
    setInstructorRefs(response.data.data.rows);
    console.log("done fetching instructors");

    return response;
  };
  const getSummary = async (row) => {
    const urlAPI = `${AppConfig.apiURL}/api/courseTaking/summary`;

    const config = {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    };
    const response = await axios.post(urlAPI, { studentId: row.id }, config);
    console.log("response: ", response.data);
    var raw = response.data.sort((a, b) => a.courseTypeId >= b.courseTypeId);
    // this section relies on courseTypeRefs and instructoRefs
    var processed = raw.map((item) => {
      var type = courseTypeRefs.find((t) => t.id === item.courseTypeId);
      var ins = instructorRefs.find(
        (i) => i.id === item.fixedCourseInstructorId
      );
      return {
        typeName: type.name,
        instructor:
          type.name === "Open" || type.name === "Trial" || type.name === "One"
            ? " "
            : ins.nickname,
        summary: `${item.courseTakeQty}/${item.qty}`,
      };
    });
    // ----------------------------------------------------------
    setState(processed);
    setInfo({
      record: response.data,
      student: {
        code: row.code,
        firstName: row.firstName,
        nickname: row.nickname,
      },
    });
  };
  useEffect(() => {
    var isCancelled = false;
    async function fetchData() {
      console.log("fetching courseTypes and Instructors...");
      await getCourseType();
      await getInstructors();
    }
    if (!isCancelled) {
      fetchData();
    }
    return () => {
      isCancelled = true;
    };
  }, []);
  useEffect(() => {
    if (courseTypeRefs != null && instructorRefs != null && row != null) {
      console.log("fetching summary...");
      getSummary(row);
    }
  }, [row, courseTypeRefs, instructorRefs]);

  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={onClose}
        style={customStyles}
        ariaHideApp={false}
      >
        {(info === null || state === null) && <Loader />}
        {info && (
          <>
            <Center>
              <Text fz="xl">{`${info.student.code}: ${info.student.nickname}`}</Text>
            </Center>
            {!!state && (
              <ul className="list-group list-group-flush">
                {!!state &&
                  state.map((item) => (
                    <li className="list-group-item">
                      {`${item.typeName} course: ${item.instructor} ${item.summary}`}
                    </li>
                  ))}
              </ul>
            )}
            <Center>
              <QRCodeSVG value={info.student.code} />
            </Center>
          </>
        )}
      </Modal>
    </>
  );
}
