import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { userSelector } from "../../../../@core/reducers/userInfoSlice";
import {
  Navbar,
  ScrollArea,
  NavLink,
  Box,
  createStyles,
  Group,
  Avatar,
  Divider,
  ActionIcon,
  Tooltip,
} from "@mantine/core";
import {
  IconServerCog,
  IconLogout,
  IconSettings,
  IconDatabase,
} from "@tabler/icons";
import { logout } from "../../../../@core/services";

const useStyles = createStyles((theme, _params, getRef) => {
  return {
    navbar: {
      backgroundColor: theme.fn.variant({
        variant: "filled",
        color: theme.primaryColor,
      }).background,
    },
  };
});

const url = "/page/";
const masterData = [
  {
    link: "users",
    label: "Users",
  },
  {
    link: "userRoles",
    label: "User Roles",
  },
  {
    link: "courses",
    label: "Courses",
  },
  {
    link: "coursetype",
    label: "Course Type",
  },
  {
    link: "message",
    label: "Message",
  },
];
const processData = [
  {
    link: "payment",
    label: "Payment",
  },
  {
    link: "coursetaking",
    label: "Course Taking",
  },
  {
    link: "booking",
    label: "Booking",
  },
  {
    link: "instructorprofile",
    label: "Instructor Profiles",
  },
  {
    link: "todayclass",
    label: "Today",
  },
];
export default function Menu() {
  const { classes, cx } = useStyles();

  const [currentFullnameOfUser, setFullname] = useState(0);
  const [imageOfUser, setImageOfUser] = useState(
    process.env.PUBLIC_URL + "/dist/img/user2-160x160.jpg"
  );
  const [active, setActive] = useState("Course Taking");

  const { userinfo } = useSelector(userSelector);
  const masterLinks = masterData.map((item, index) => (
    <NavLink
      key={`master-${index}`}
      icon={<IconDatabase size={15} />}
      to={`${url}${item.link}`}
      label={item.label}
      component={Link}
      onClick={() => setActive(item.label)}
      active={active === item.label}
      variant="light"
    />
  ));
  const processLinks = processData.map((item, index) => (
    <NavLink
      key={`process-${index}`}
      icon={<IconDatabase size={15} />}
      to={`${url}${item.link}`}
      label={item.label}
      component={Link}
      onClick={() => setActive(item.label)}
      active={active === item.label}
      variant="light"
    />
  ));
  useEffect(() => {
    setFullname(userinfo.firstName);
    setImageOfUser(userinfo.imagePath);
  }, [userinfo]);

  return (
    <Navbar
      p="md"
      hiddenBreakpoint="sm"
      width={{ sm: 200, lg: 300 }}
      // className={classes.navbar}
    >
      <Navbar.Section mb={10}>
        <Group noWrap position="apart">
          <Avatar src={imageOfUser} size={50} radius="md" />
          {currentFullnameOfUser}
          <Tooltip label="Logout">
            <ActionIcon onClick={logout}>
              <IconLogout />
            </ActionIcon>
          </Tooltip>
        </Group>
      </Navbar.Section>
      <Divider />
      <Navbar.Section grow component={ScrollArea} mx="-xs" px="xs" mt={10}>
        <Box>
          <NavLink
            label="Master"
            icon={<IconServerCog />}
            variant="filled"
            active
            mb={10}
          >
            {masterLinks}
          </NavLink>
          <NavLink
            label="Process"
            icon={<IconSettings />}
            variant="filled"
            active
            defaultOpened
          >
            {processLinks}
          </NavLink>
          {/* <NavLink
            label="Master"
            icon={<IconServerCog />}
            variant="light"
            active
          >
            <NavLink
              to="/page/users"
              component={Link}
              label={"Users"}

             
            />
            <NavLink
              to="/page/userRoles"
              component={Link}
              label={"User Role"}
            />
          </NavLink> */}
          {/* <Link to="/page/users">Users</Link>

          <Link to="/page/userRoles">User Role</Link>
          <Link to="/page/courses">Courses</Link>
          <Link to="/page/coursetype">Course Type</Link>
          <Link to="/page/message">Message</Link>
          <Link to="/page/payment">Payment</Link>
          <Link to="/page/coursetaking">Course Taking</Link>
          <Link to="/page/booking">Booking</Link>
          <Link to="/page/instructorprofile">Instructor Profile</Link>
          <Link to="/page/todayclass">Today Class</Link>  */}
        </Box>
      </Navbar.Section>
      <Navbar.Section></Navbar.Section>
    </Navbar>
  );
}
