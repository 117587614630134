import React from "react";
import { Switch, Route } from "react-router-dom";
import CourseManage from "../../../../screens/master/Courses/CourseManage";
import CoursesList from "../../../../screens/master/Courses/CoursesList";
import CourseTypeList from "../../../../screens/master/CourseType/CourseTypeList";
import MessageList from "../../../../screens/master/Message/MessageList";
import UserList from "../../../../screens/master/user/UserList";
import UserManage from "../../../../screens/master/user/UserManage";
import UserRoleList from "../../../../screens/master/UserRole/UserRoleList";
import CourseTakingList from "../../../../screens/process/CourseTaking/CourseTakingList";
import CourseTakingManage from "../../../../screens/process/CourseTaking/CourseTakingManage";
import Payment from "../../../../screens/process/Payment/Payment";
import PaymentManage from "../../../../screens/process/Payment/PaymentManage";
import CoursesReport from "../../../../screens/report/Courses/CoursesReport";
import BookingList from "../../../../screens/process/Booking/BookingList";
import BookingManage from "../../../../screens/process/Booking/BookingManage";

import InstructorList from "../../../../screens/process/InstructorProfiles/InstructorList";
import InstructorProfileManage from "../../../../screens/process/InstructorProfiles/InstructorProfileManage";

import TodayClassList from "../../../../screens/process/TodayClass/TodayClassList";
import TodayClassManage from "../../../../screens/process/TodayClass/TodayClassManage";
import { Container } from "@mantine/core";
export default function Content() {
  return (
    // <div className="content-wrapper">
    <Container fluid>
      <Switch>
        <Route exact path="/page/payment" component={Payment} />
        <Route exact path="/page/payment/new" component={PaymentManage} />
        <Route exact path="/page/payment/:id" component={PaymentManage} />
        <Route exact path="/page/users" component={UserList} />
        <Route exact path="/page/users/new" component={UserManage} />
        <Route exact path="/page/users/edit/:id" component={UserManage} />
        <Route exact path="/page/coursetaking" component={CourseTakingList} />
        <Route
          exact
          path="/page/coursetaking/new"
          component={CourseTakingManage}
        />
        <Route exact path="/page/courses" component={CoursesList} />
        <Route exact path="/page/courses/new" component={CourseManage} />
        <Route exact path="/page/courses/edit/:id" component={CourseManage} />
        <Route exact path="/page/coursetype" component={CourseTypeList} />

        <Route exact path="/page/message" component={MessageList} />
        <Route exact path="/page/userRoles" component={UserRoleList} />
        <Route exact path="/page/report/courses" component={CoursesReport} />

        <Route exact path="/page/booking" component={BookingList} />
        <Route exact path="/page/booking/edit/:id" component={BookingManage} />
        <Route exact path="/page/booking/new" component={BookingManage} />

        <Route
          exact
          path="/page/instructorprofile"
          component={InstructorList}
        />
        <Route
          exact
          path="/page/instructorprofile/new"
          component={InstructorProfileManage}
        />
        <Route
          exact
          path="/page/instructorprofile/edit/:id"
          component={InstructorProfileManage}
        />

        <Route exact path="/page/todayclass" component={TodayClassList} />
        <Route exact path="/page/todayclass/new" component={TodayClassManage} />
        <Route
          exact
          path="/page/todayclass/edit/:id"
          component={TodayClassManage}
        />
      </Switch>
    </Container>
    // </div>
  );
}
