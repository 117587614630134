import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useParams } from "react-router";
import Swal from "sweetalert2/dist/sweetalert2";
import { isNullEmpty, getTimezoneValue } from "../../../@core/utilities";
import { AppConfig } from "../../../AppConfig";
import axios from "axios";
import { getToken, getUserId } from "../../../@core/services";
import CourseService from "../../../services/master/CourseService";
import UserService from "../../../services/master/UserService";
import Label from "../../../@core/components/Label";
import { format, utcToZonedTime } from "date-fns-tz";
import { createSubmitObject } from "./helper";
import { Container, Center } from "@mantine/core";
export default function CourseManage(props) {
  const { id } = useParams();
  const { register, handleSubmit, setValue } = useForm();
  const [instructorOptions, setInstructorOptions] = useState(null);
  const [method, setMethod] = useState("NEW");
  const [courseTypeOptions, setCourseTypeOptions] = useState(null);
  const [instructorSelected, setInstructorSelected] = useState("");
  const [instructorDefault, setInstructorDefault] = useState();
  const [courseTypeSelected, setCourseTypeSelected] = useState("");
  const [courseTypeDefault, setCourseTypeDefault] = useState();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  // Not null if Edit & courseType is "Open"
  const [oneClass, setOneClass] = useState(null);
  const [trialClass, setTrialClass] = useState(null);

  useEffect(() => {
    async function fetchData() {
      const handler = new CourseService();
      const objinfo = await handler.get(id);
      if (objinfo !== null) {
        setValue("courseName", objinfo.courseName);
        var timeZoneValue = getTimezoneValue();
        if (!!objinfo.startCourse) {
          var localDate = utcToZonedTime(
            new Date(objinfo.startCourse),
            timeZoneValue
          );
          setStartDate(localDate);
        }
        if (!!objinfo.endCourse) {
          var localDate = utcToZonedTime(
            new Date(objinfo.endCourse),
            timeZoneValue
          );
          setEndDate(localDate);
        }
        await getInstructorOptions(objinfo.instructorId);
        await getCourseTypesOptions(objinfo.courseTypeId);
      } else {
        Swal.fire({
          title: "Error!",
          text: "Data not found",
          icon: "error",
          confirmButtonText: "Ok",
        });
        props.history.push(`${AppConfig.applicationPath}/courses`);
      }
    }
    if (!isNullEmpty(id)) {
      setMethod("EDIT");
      Swal.fire({
        title: "Please Wait !",
        html: "System processing",
        allowOutsideClick: false,
        showCancelButton: false,
        showConfirmButton: false,
        willOpen: () => {
          Swal.showLoading();
          fetchData();
          Swal.close();
        },
      });
    } else {
      getInstructorOptions();
      getCourseTypesOptions();
    }
  }, []);
  const backOnClick = () => {
    props.history.push(`${AppConfig.applicationPath}/courses`);
  };
  const isDisabledOption = (option) => {
    return (
      option.label.toLowerCase() === "trial" ||
      option.label.toLowerCase() === "one"
    );
  };
  const onSubmit = async (data) => {
    var startDateValue = null;
    var endDateValue = null;
    startDateValue = format(startDate, "yyyy-MM-dd HH:mm");
    endDateValue = format(endDate, "yyyy-MM-dd HH:mm");

    if (
      data.courseName === "" ||
      courseTypeSelected === "" ||
      instructorSelected === ""
    ) {
      Swal.fire({
        title: "Error!",
        text: "Data not complete",
        icon: "error",
        confirmButtonText: "Ok",
      });
      return false;
    }
    const isNew = method === "NEW";
    const dataSubmit = isNew
      ? {
          courseName: data.courseName,
          instructorId: instructorSelected,
          startTime: startDateValue,
          endTime: endDateValue,
          userId: getUserId(),
          courseTypeId: courseTypeSelected,
        }
      : {
          courseId: id,
          courseName: data.courseName,
          instructorId: instructorSelected,
          startTime: startDateValue,
          endTime: endDateValue,
          userId: getUserId(),
          courseTypeId: courseTypeSelected,
        };

    const handler = new CourseService();
    let result = null;
    var submitObjects;
    const submitHandler = async (obj) =>
      isNew ? await handler.insert(obj) : await handler.update(obj);
    submitObjects = createSubmitObject(
      dataSubmit,
      courseTypeOptions,
      oneClass,
      trialClass
    );
    console.log("submitObjects: ", submitObjects);
    try {
      if (submitObjects.isOpen) {
        var open, one, trial;

        open = await submitHandler(submitObjects.data.open);
        one = await submitHandler(submitObjects.data.one);
        trial = await submitHandler(submitObjects.data.trial);
        result = { open, one, trial, status: "00" };
      } else {
        result = await submitHandler(submitObjects.data);
      }
    } catch (e) {
      result = { status: "Error", error: e };
    }
    if (!!result.status && result.status === "00") {
      Swal.fire("Success!", "", "success");
      props.history.goBack();
    } else {
      Swal.fire("Error!", `${JSON.stringify(result)}`, "error");
    }

    // if (method === "NEW") {
    //   const dataSubmit = {
    //     courseName: data.courseName,
    //     instructorId: instructorSelected,
    //     startTime: startDateValue,
    //     endTime: endDateValue,
    //     userId: getUserId(),
    //     courseTypeId: courseTypeSelected,
    //   };
    //   result = await handler.insert(dataSubmit);
    // } else if (method === "EDIT") {
    //   const dataSubmit = {
    //     courseId: id,
    //     courseName: data.courseName,
    //     instructorId: instructorSelected,
    //     startTime: startDateValue,
    //     endTime: endDateValue,
    //     userId: getUserId(),
    //     courseTypeId: courseTypeSelected,
    //   };
    //   result = await handler.update(dataSubmit);
    // }
    // if (result !== null) {
    //   if (result.status === "00") {
    //     Swal.fire({
    //       icon: "info",
    //       title: "success",
    //       html: "Save data success.",
    //     }).then(() => {
    //       props.history.push(`${AppConfig.applicationPath}/courses`);
    //     });
    //   }
    // }

    // return false;
  };
  const getInstructor = async (id) => {
    const handler = new UserService();
    const response = await handler.get(id);
    console.log("instructorOptions: ", instructorOptions);
    var user = instructorOptions.find((i) => i.value === id);
    console.log("user: ", user);
    setInstructorDefault(user);
    setValue("instructorSelected", user.value);
    setInstructorSelected(user.value);
  };
  const getInstructorOptions = async (id = null) => {
    const handler = new UserService();
    const data = await handler.getInstructors();

    if (data !== undefined) {
      const options = data
        .map((d) => {
          if (d.roleName === "Instructor") {
            const val = {
              value: d.id,
              label: `${d.nickname}`,
            };
            return val;
          }
        })
        .filter((user) => user !== undefined);

      if (options === undefined) setInstructorOptions(null);
      else {
        setInstructorOptions(options);
        if (!!id) {
          var ins = options.find((i) => i.value === id);
          setInstructorDefault(ins);
          setValue("instructorSelected", ins.value);
          setInstructorSelected(ins.value);
        }
      }
    } else {
      setInstructorOptions(null);
    }
  };
  const getCourseTypesOptions = async (id = null) => {
    const config = {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    };
    const urlAPI = `${AppConfig.apiURL}/api/courseTypes?page=${1}&size=${1000}`;

    const response = await axios.post(urlAPI, null, config);
    const data = response.data.data.rows;
    if (data !== undefined) {
      const options = data.map((d) => ({
        value: d.id,
        label: d.name,
      }));
      setCourseTypeOptions(options);
      if (!!id) {
        var type = options.find((t) => t.value === id);
        setCourseTypeSelected(type.value);
        setCourseTypeDefault(type);
      }
    } else {
      setCourseTypeOptions(null);
    }
  };
  const handleInstructor_OnChange = (e) => {
    setInstructorSelected(e.value);
    setInstructorDefault({
      value: e.value,
      label: e.label,
    });
  };
  const handleCourseType_OnChange = (e) => {
    setCourseTypeSelected(e.value);
    setCourseTypeDefault({
      value: e.value,
      label: e.label,
    });
  };
  const fetchCourseSet = async (id) => {
    var handler = new CourseService();
    var response = await handler.getCourseSet(id);
    console.log("response: ", response);
    const one = courseTypeOptions.find((t) => t.label.toLowerCase() === "one");
    const trial = courseTypeOptions.find(
      (t) => t.label.toLowerCase() === "trial"
    );
    console.log("One: ", one);
    console.log("Trial: ", trial);

    setOneClass(response.find((c) => c.COURSETYPEID === one.value));
    setTrialClass(response.find((c) => c.COURSETYPEID === trial.value));
  };
  useEffect(() => {
    if (
      courseTypeOptions != null &&
      courseTypeDefault != null &&
      method === "EDIT" &&
      courseTypeDefault.label.toLowerCase() === "open"
    ) {
      console.log(`${method}: ${courseTypeDefault.label} => Fething courseSet`);
      fetchCourseSet(id);
    }
  }, [courseTypeDefault, method, courseTypeOptions]);
  return (
    <Container size="md">
      <Center style={{ paddingTop: 10 }}>
        <div className="row" style={{ marginBottom: 130 }}>
          <div className="col-12">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="card card-outline">
                <div className="card-header">
                  <h3 className="card-title">
                    <i className="fas fa-edit" />
                    Course
                  </h3>
                </div>
                <div className="card-body" style={{ paddingTop: 5 }}>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <Label text="Course Name" required />
                        <input
                          type="text"
                          name="courseName"
                          className="form-control"
                          ref={register()}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <Label text="Instructor Name" required />

                        <Select
                          name="instructorSelected"
                          value={instructorDefault}
                          options={instructorOptions}
                          onChange={handleInstructor_OnChange.bind(this)}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <Label text="Course Type" required />

                        <Select
                          name="courseTypeNameSelected"
                          value={courseTypeDefault}
                          options={courseTypeOptions}
                          onChange={handleCourseType_OnChange.bind(this)}
                          isOptionDisabled={(option) =>
                            isDisabledOption(option)
                          }
                          isDisabled={method === "EDIT"}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <label>Start Times - End Times</label>
                      <div className="form-group">
                        <DatePicker
                          name="startDate"
                          selected={startDate}
                          dateFormat="dd/MM/yyyy HH:mm"
                          timeFormat="HH:mm"
                          className="form-control"
                          showTimeSelect
                          onChange={(date) => setStartDate(date)}
                        />
                        <label className="mr-1 ml-1">TO</label>
                        <DatePicker
                          name="endDate"
                          selected={endDate}
                          dateFormat="dd/MM/yyyy HH:mm"
                          timeFormat="HH:mm"
                          className="form-control"
                          showTimeSelect
                          onChange={(date) => setEndDate(date)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-footer">
                  <button type="submit" className="btn btn-default mr-1">
                    Save
                  </button>
                  <button
                    type="button"
                    className="btn btn-default"
                    onClick={backOnClick}
                  >
                    Back
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Center>
    </Container>
  );
}
